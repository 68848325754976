.container_search_direction {
	width: 100%;
	background: white;
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 15px;
	overflow-y: scroll;
	max-height: 200px;
	height: auto;
}

.container_search_direction .subcontainer_search {
	cursor: pointer;
	display: flex;
	margin-bottom: 11px;
	justify-content: space-between;
	width: 70%
}

.container_search_direction .subcontainer_search:hover {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search .text_search {
	font-size: 12px;
	color: #131523;
	width: 50%;
	/* Limita el ancho del primer span */
	white-space: nowrap;
	/* Evita que el texto se divida en varias líneas */
	overflow: hidden;
	/* Oculta el texto que excede el ancho del span */
	text-overflow: ellipsis;
	/* Agrega puntos suspensivos al final del texto oculto */
}

.subcontainer_search:hover .text_search {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search .text_right_search {
	width: 40%;
	text-align: left;
}