.serviceOrdersList-mainPage-container {
    display: flex;
    flex-flow: column;
    padding-top: 34.57px;
}

.serviceOrdersList-mainPage-container .content-filter-items {
    display: flex;
    flex-flow: column;
    padding-left: 42px !important;
    padding-right: 41px !important;
    margin-bottom: 40.87px;
}

.serviceOrdersList-mainPage-container .content-filter-items .subtitle {
    color: #2B80FF;
    font-weight: bold;
    font-size: 14px;
}

.serviceOrdersList-mainPage-container .content-filter-items .column {
    display: flex;
    flex-flow: column;
}

.serviceOrdersList-mainPage-container .content-filter-items .position-end {
    margin-left: auto;
}

.serviceOrdersList-mainPage-container .content-filter-items .search {
    width: 219px;
    margin-left: auto;
}

.serviceOrdersList-mainPage-container .content-filter-items .row {
    display: flex;
    align-items: center;
    margin: inherit;
    padding: inherit;
}

// --------------------

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: white;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    color: #868D96;
    font-size: 12px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    color: #868D96;
    font-size: 12px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-item {
    position: relative;
    display: block;
    min-height: 40px;
    padding: 5px 12px;
    color: #868D96;
    font-size: 12px;
    font-weight: normal;
    line-height: 22px;
    cursor: pointer;

}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-single:not(.ant-select-customize-input),
.serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-selector .ant-select-selection-search-input {
    height: 40px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker {
    color: #868D96;
    font-size: 12px;
    padding: 4px 11px 4px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: white;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    height: 40px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-item-option-content {
    color: #868D96;
    font-size: 12px;
    padding: 4px 11px 4px;
    background: white;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker-input>input {
    color: #949594;
    font-size: 12px;
    background-color: white;
    border-radius: 5px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group .ant-input {
    height: 30px !important;
    font-size: 12px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    height: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 5px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group .ant-input-affix-wrapper:not(:last-child) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #E0E0E0;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-affix-wrapper {
    color: #868D96;
    font-size: 12px;
    border: 1px solid #E0E0E0;
    height: 40px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    background: white;
    // border: none;
}

.serviceOrdersList-mainPage-container .content-filter-items .row.ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 5px;
    //  height: 40px !important;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    height: 40px !important;
    border: none !important;
    border-bottom-right-radius: 5px !important;
    border-top-right-radius: 5px !important;
    border: 1px solid #E0E0E0 !important;
}

.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group {
    font-size: 12px
}

.serviceOrdersList-mainPage-container .ant-table-filter-trigger {
    margin-right: 42px;
}

// --------------
// --------------
// estilos personalizados del Search Component de ant design
.serviceOrdersList-mainPage-container .content-filter-items .row .input-search-box-content {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}


.serviceOrdersList-mainPage-container .content-filter-items .row .input-search-box-content .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    border: none;
    outline: none;
    background: white;
}

.serviceOrdersList-mainPage-container>div.content-filter-items>div>div.input-search-box-content>span>span>span.ant-input-affix-wrapper {
    background: white;
    border: none !important;
    outline: none !important;
    border-radius: none !important;
}

.serviceOrdersList-mainPage-container>div.content-filter-items>div>div.input-search-box-content>span>span {
    background: white;
    border: none;
    outline: none;
    border-radius: none;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
}

.serviceOrdersList-mainPage-container .row .content-filter-items .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    height: 40px !important;
    border: none !important;
    border-bottom-right-radius: none !important;
    border-top-right-radius: none !important;
    border: none !important;
}

// -----------------
// -----------------

.serviceOrdersList-mainPage-container .row .content-filter-items .ant-input-affix-wrapper {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0;
    background: white;
    border: none;
    outline: none;
}

.serviceOrdersList-mainPage-container .row .content-filter-items .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    height: 40px;
    border: none;
    outline: none;
    background: white;
}

.serviceOrdersList-mainPage-container .row .content-filter-items .ant-input-affix-wrapper:focus,
.serviceOrdersList-mainPage-container .row .content-filter-items .ant-input-affix-wrapper-focused {
    box-shadow: none;
    background: white;
}

.serviceOrdersList-mainPage-container .row .content-filter-items .ant-btn.ant-btn-icon-only {
    width: 25px;
    padding-inline-start: 0;
    padding-inline-end: 0;
    background: white;
}



.serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    height: 40px !important;
    border: none !important;
    border-bottom-right-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border: 1px solid white !important;
}

 .ant-input-affix-wrapper:focus,
 .ant-input-affix-wrapper-focused {
    box-shadow: none;
    background: white;
}



















@media screen and (min-width:1280px) {
    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        font-size: 12px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker {
        font-size: 12px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker-input>input {
        font-size: 12px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group {
        font-size: 12px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group .ant-input {
        font-size: 12px;
    }
}

@media screen and (min-width: 1366px) {
    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        font-size: 13.5px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker {
        font-size: 13.5px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker-input>input {
        font-size: 13.5px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group {
        font-size: 13.5px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group .ant-input {
        font-size: 13.5px;
    }
}

@media screen and (min-width: 1920px) {
    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
        font-size: 15.18px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker {
        font-size: 15.18px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-picker-input>input {
        font-size: 15.18px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group {
        font-size: 15.18px;
    }

    .serviceOrdersList-mainPage-container .content-filter-items .row .ant-input-group .ant-input {
        font-size: 15.18px;
    }
}