.container {
	display: flex;
	flex-direction: column;
	background: #F7FAFC;
	border: 1px solid #DDE4EB;
	padding: 17px 21px 17.5px 21px;
	margin-top: 14.8px;
}

.text {
	font-size: 12px;
	color: #131523;
	margin-bottom: 5px;
}

.checkbox {
	font-size: 12px;
	color: #131523;
	font-weight: bold;
}

@media(width >=1280px) {
	.text {
		font-size: 12px;
	}

	.checkbox {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.text {
		font-size: 13.5px;
	}

	.checkbox {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.text {
		font-size: 15.18px;
	}

	.checkbox {
		font-size: 15.18px;
	}
}