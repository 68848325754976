.container_tooltip {
	display: flex;
	flex-direction: column;
}

.tittle_tooltip {
	color: #868E96;
	font-size: 14px;
	margin-bottom: 15px;
}

.description_tooltip {
	color: #172B4D;
	font-size: 12px;
	width: 155px;
}

.container_header {
	background: #f3f3f3;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: 110px;
}

.container_header .top {
	display: flex;
	justify-content: space-between;
}

.container_header .sub_container_header {
	display: flex;
	align-items: baseline;
}

.container_header .title_header {
	color: #001737;
	font-weight: bold;
	margin: 0;
	padding: 0;
	font-size: 24px;
}

.container_header .sub_text_header {
	color: #001737;
	font-size: 12px;
}

.button_header {
	width: 182px;
	height: 40px;
	border: 1px solid #2B80FF;
	background: #FFFFFF;
	color: #2B80FF;
	border-radius: 5px;
	font-size: 13px;
}

.button_header:hover {
	background: #2B80FF;
	color: #FFFFFF;
}

/* container */

.container {
	display: flex;
	flex-flow: column;
	padding-top: 50px;
}

.container .text {
	color: #5A607F;
	font-size: 14px;
	margin: 0;
	padding-left: 43px;
}

.container .conatiner_filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 63px;
	padding-bottom: 29px;
}

.container_pagination {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-top: 43.87px;
	padding-right: 41px;
	padding-bottom: 33px;
}

.container_pagination .sub_container_export {
	padding-left: 40px;
}

.container_pagination .sub_container_export .text_pagination {
	display: flex;
}

.container_pagination .export {
	cursor: pointer;
}

.container_pagination .sub_container_export .text_export {
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
	margin-left: 6px;
}

.button_table {
	color: #2B80FF;
	background: transparent;
	font-weight: bold;
	font-size: 12px;
}

@media(width >=1280px) {
	.container_header .title_header {
		font-size: 24px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_header {
		width: 182px;
		height: 40px;
		font-size: 13px;
	}

	.container .text {
		font-size: 14px;
	}

	.container_pagination .sub_container_export .text_export {
		font-size: 14px;
	}

	.button_table {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.container_header .title_header {
		font-size: 27px;
	}

	.container_header .sub_text_header {
		font-size: 13.5px;
	}

	.button_header {
		width: 204.75px;
		height: 45px;
		font-size: 14.625px;
	}

	.container .text {
		font-size: 15.75px;
	}

	.container_pagination .sub_container_export .text_export {
		font-size: 15.75px;
	}

	.button_table {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.container_header .title_header {
		font-size: 30.37px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_header {
		width: 230.34px;
		height: 50.625px;
		font-size: 16.45px;
	}

	.container .text {
		font-size: 17.71px;
	}

	.container_pagination .sub_container_export .text_export {
		font-size: 17.71px;
	}

	.button_table {
		font-size: 15.18px;
	}
}