.my__custom__single__button__styles {
    border: none;
    outline: none;

    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px
}
.my__custom__single__button__styles .button__text__content {
    font-size: 13px;
    text-align: center;
    margin: 0;
    padding: 0;
}

@media screen and (min-width: 1280px) {
    .my__custom__single__button__styles .button__text__content {
        font-size: 12px
    }
}

@media screen and (min-width: 1366px) {
    .my__custom__single__button__styles .button__text__content {
        font-size: 13.5px;
    }
}

@media screen and (min-width: 1920px) {
    .my__custom__single__button__styles .button__text__content {
        font-size: 15.18px;
    }
}