.flex_column {
	display: flex;
	flex-direction: column;
}

.bold {
	font-weight: bold;
}

.margin_top35 {
	margin-top: 35px;
}

.text12font {
	font-size: 12px;
}

.text14font {
	font-size: 14px;
}

.text20font {
	font-size: 20px;
}

.color959595 {
	color: #959595;
}

.color172B4D {
	color: #172B4D;
}

.container_subtitle {
	display: flex;
	justify-content: space-between;
}

.container_detail_order {
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
}

.subcontainer_detail_order {
	display: flex;
	align-items: flex-end;
	gap: 20px;
}

.container_detail_order p {
	margin: 0;
}

.text_subtitle {
	font-size: 12px;
	color: #172B4D;
	font-weight: bold;
	margin-top: 15px;
}

.container_upload {
	position: relative;
}

.modal {
	display: flex;
	align-items: center;
	justify-content: center;
	position: absolute;
	width: 100%;
	height: 110%;
	top: 0;
	left: 0;
	background: hsla(0, 0%, 0%, 0.5);
	z-index: 10;
}

.container_modal {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 393px;
	height: 432px;
	background: #FFFFFF;
	border-radius: 5%;
	padding: 19px 20px 20px 17px;
}

.title_image {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin-bottom: 10px;
}

.title_image p {
	margin: 0;
}

.icon_close {
	font-size: 10px;
	color: #959595;
}

@media(width >=1280px) {
	.text12font {
		font-size: 12px;
	}

	.text14font {
		font-size: 14px;
	}

	.text20font {
		font-size: 20px;
	}

	.text_subtitle {
		font-size: 12px;
	}

	.icon_close {
		font-size: 10px;
	}
}

@media(width >=1366px) {
	.text12font {
		font-size: 13.5px;
	}

	.text14font {
		font-size: 15.75px;
	}

	.text20font {
		font-size: 22.5px;
	}

	.text_subtitle {
		font-size: 13.5px;
	}

	.icon_close {
		font-size: 11.25px;
	}
}

@media(width >=1920px) {
	.text12font {
		font-size: 15.18px;
	}

	.text14font {
		font-size: 17.71px;
	}

	.text20font {
		font-size: 25.31px;
	}

	.text_subtitle {
		font-size: 15.18px;
	}

	.icon_close {
		font-size: 12.65px;
	}
}