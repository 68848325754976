* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.labelDrawer__text {
	color: #131523;
	font-weight: 400;
	font-size: 12px;
	display: inline-flex;
	margin-bottom: 11px !important;
}

.blue__asterisc {
	color: #2B80FF;
	font-weight: 400;
	font-size: 12px;
	display: contents;
}

@media screen and (min-width: 1280px) {

	.labelDrawer__text {
		font-size: 12px;
	}

	.blue__asterisc {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {

	.labelDrawer__text {
		font-size: 13.5px;
	}

	.blue__asterisc {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {

	.labelDrawer__text {
		font-size: 15.18px;
	}

	.blue__asterisc {
		font-size: 15.18px;
	}
}