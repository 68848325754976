.container_client {
	display: flex;
	gap: 16px;
	margin-bottom: 9px;
}

.container_client .text_ot_client {
	font-size: 12px;
	color: #131523;
	font-weight: bold;
	margin: 0;
}

.container_client .container_switch {
	width: 86px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container_client .container_switch .text_switch {
	font-size: 12px;
	color: #131523;
	margin: 0;
}

.container_type_client {
	display: flex;
	flex-direction: column;
	width: 45%;
}

.text_blue {
	color: #2B80FF;
	font-weight: bold;
}

@media(width >=1280px) {
	.container_client .text_ot_client {
		font-size: 12px;
	}

	.container_client .container_switch .text_switch {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.container_client .text_ot_client {
		font-size: 13.5px;
	}

	.container_client .container_switch .text_switch {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.container_client .text_ot_client {
		font-size: 15.18px;
	}

	.container_client .container_switch .text_switch {
		font-size: 15.18px;
	}
}