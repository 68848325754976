.container_tooltip {
	display: flex;
	flex-direction: column;
	padding: 15px 30px 19.82px 15.31px;
	flex-wrap: wrap;
	gap: 5px;
}

.tittle {
	color: #868E96;
	font-size: 14px;
	padding-bottom: 9px;
}

.legend {
	font-size: 12px;
	font-weight: bold;
	color: #172B4D;
	margin: 0;
}

.container_legend {
	display: flex;
	width: auto;
	gap: 30px;
}

.container_div {
	display: flex;
	align-items: center;
	width: 100%;
	padding-left: 5px
}

.title_order {
	font-size: 12px;
	color: #172B4D;
	margin: 0;
}