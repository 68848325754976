
.row {
    width: 100%;
    display: flex;
    align-items: center;
    margin: 0 !important;
    padding: 0 !important;
}

.column {
    width: 100%;
    display: flex;
    flex-flow: column;
}

.row_center {
    justify-content: center;
}

.flex-end {
    justify-content: flex-end;
}

.space-between {
    justify-content: space-between;
}

.column-between {
    align-items: space-between;

}

.margin-top {
    margin-top: 17px;
}

// ----------------------------------------

.left {
    width: 49%;
}

.right {
    width: 49%;
}

// ----------------------------------------

.input {
    width: 100%;
    height: 40px;
    background: white;
    outline: none;
    border: none;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    padding: 4px 11px 4px;
    color: #868D96;

    ::placeholder {
        font-weight: bold;
        opacity: 0.5;
        color: #868D96;
    }

    :focus {
        border-color: #40a9ff;
        // box-shadow: 0 0 0 2px rgb(24 144 255 / 20%);
        border-right-width: 1px;
    }
}

.ant-select-selector {
    height: 40px;
    border-radius: 5px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    position: relative;
    background-color: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 5px;
}

.ant-select-selection-item {
    padding-top: 5px !important;
}

.ant-input-number-sm input {
    height: 22px;
    padding: 10px 7px 0 7px;
    color: #868D96;
    font-size: 12px
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    width: 100%;
    height: 40px;
    padding: 0 11px;
}

.add_button {
    width: 91px;
    height: 40px;
    background: #DDE4EB;
    border: none;
    outline: none;

    border: 1px solid #0060FF;
    border-radius: 3px;
    color: #001737;
    font-size: 13px;
    text-align: center;

    :hover {
        background: #2C91FC;
        color: white;
    }
}

.delete_noBtn {
    font-size: 12px;
    color: #2B80FF;
    font-weight: bold;
    border: none;
    outline: none;
    margin: 2px 0;
    background: white;
    position: inherit;
    position: relative !important;
}

.delete_tooltip {
    width: 197px;
    height: 124px;

    position: absolute;
    top: 20px;
    right: 100px;
    border-radius: 4px;
    background: #00388B;
    color: white;
    padding: 1em;
    display: flex;
    flex-flow: column;
    z-index: 1;
}

.btn__,
.btn__save {
    width: 83px;
    height: 40px;
    border: none;
    outline: none;
    border-radius: 3px;
}

.btn__ {
    background: white;
    border: 1px solid #BFCCDA;

    :hover {
        background: #DDE4EB;
        border: none;
    }
}

.btn__save {
    background: #DDE4EB;
    border: 1px solid #0060FF;

    :hover {
        background: #2B80FF;
        color: white;
    }
}