.container_img {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 87px 0 20px 0;
	height: 539px;
}

.text_p {
	margin: 0 !important;
	font-size: 14px;
	color: #5A607F;
}

.color_blue {
	color: #2B80FF;
}

.title_empty {
	font-size: 20px;
	color: #5A607F;
	margin-bottom: 11px;
}

.button {
	width: 30px;
	height: 30px;
	background: white;
	border: 1px solid #2B80FF;
	border-radius: 5px;
	margin-right: 95px;
}

.container_options {
	position: absolute;
	transform: translate(-73px, 1px);
	background: white;
	border: 1px solid #ddd;
	width: 102px;
	height: 74px;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.container_button_options {
	position: relative;
}

.text_modify {
	font-size: 12px;
	margin-top: 17px;
	margin-bottom: 12px;
	color: #131523;
	cursor: pointer;
}

.text_delete {
	font-size: 12px;
	color: #131523;
	margin-bottom: 8px;
	cursor: pointer;
}

@media(width >=1280px) {
	.text_p {
		font-size: 14px;
	}

	.title_empty {
		font-size: 20px;
	}

	.text_modify {
		font-size: 12px;
	}

	.text_delete {
		font-size: 12px;
	}

	.container_options {
		transform: translate(-73px, 1px);
		width: 102px;
		height: 74px;
	}
}

@media(width >=1366px) {
	.text_p {
		font-size: 15.75px;
	}

	.title_empty {
		font-size: 22.5px;
	}

	.text_modify {
		font-size: 13.5px;
	}

	.text_delete {
		font-size: 13.5px;
	}

	.container_options {
		transform: translate(-85px, 1px);
		width: 114.75px;
		height: 83.25px;
	}
}

@media(width >=1920px) {
	.text_p {
		font-size: 17.71px;
	}

	.title_empty {
		font-size: 25.31px;
	}

	.text_modify {
		font-size: 15.18px;
	}

	.text_delete {
		font-size: 15.18px;
	}

	.container_options {
		transform: translate(-98px, 1px);
		width: 129px;
		height: 93.6px;
	}
}