.container_name {
	display: flex;
	flex-direction: column;
	margin-bottom: 15px;
}

.container_color {
	display: flex;
	justify-content: space-between;
}

.subcontainer_label {
	display: flex;
	flex-direction: column;
	width: 45%;
}

.colorpicker {
	width: 100%;
	height: 40px;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 5px;
	padding-left: 13px;
}

.subcontainer_color {
	display: flex;
	flex-direction: column;
	width: 45%;
}

.text_color {
	color: #868D96;
	margin: 0;
	font-size: 12px;
}