.container_name {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.container_date_type {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.subcontainer_date {
	display: flex;
	flex-direction: column;
	width: 48%;
}

.container_technicial {
	display: flex;
	flex-direction: column;
}

.text_hability {
	margin-right: 16px;
	font-size: 12px;
}