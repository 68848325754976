.container {
	width: 100%;
	border: 0.5px solid #7070702E;
	border-radius: 5px;
	padding: 22px 25px 11px 16px;
}

.header {
	display: flex;
	justify-content: space-between;
}

.container_data {
	display: flex;
	gap: 30px
}

.subcontaier_data {
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
}

.subcontaier_data label {
	margin-bottom: 3px;
}

.text_name {
	font-size: 14px;
	color: #5A607F;
	font-weight: bold;
}

.text_document {
	font-size: 14px;
	color: #959595;
}

.text_title_direction {
	font-size: 12px;
	color: #5A607F;
	font-weight: bold;
}

.text_direction {
	font-size: 12px;
	color: #959595;
}

.text_number {
	font-size: 14px;
	color: #2B80FF;
	font-weight: bold;
}

.avatar {
	width: 50px;
	height: 50px;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.subcontainer_button {
	display: flex;
	flex-direction: column;
}

.linea {
	width: 70%;
	height: 5px;
	margin: auto;
	background: #2B80FF;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}

@media(width >=1280px) {
	.text_name {
		font-size: 14px;
	}

	.text_document {
		font-size: 14px;
	}

	.text_title_direction {
		font-size: 12px;
	}

	.text_direction {
		font-size: 12px;
	}

	.text_number {
		font-size: 14px;
	}

	.avatar {
		width: 50px;
		height: 50px;
		font-size: 18px;
	}
}

@media(width >=1366px) {
	.text_name {
		font-size: 15.75px;
	}

	.text_document {
		font-size: 15.75px;
	}

	.text_title_direction {
		font-size: 13.5px;
	}

	.text_direction {
		font-size: 13.5px;
	}

	.text_number {
		font-size: 15.75px;
	}

	.avatar {
		width: 56.25px;
		height: 56.25px;
		font-size: 20.25px;
	}
}

@media(width >=1920px) {
	.text_name {
		font-size: 17.71px;
	}

	.text_document {
		font-size: 17.71px;
	}

	.text_title_direction {
		font-size: 15.18px;
	}

	.text_direction {
		font-size: 15.18px;
	}

	.text_number {
		font-size: 17.71px;
	}

	.avatar {
		width: 63.28px;
		height: 63.28px;
		font-size: 22.78px;
	}
}