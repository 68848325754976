.container_general {
	padding: 31.7px 74.25px 0 30.25px;
	display: flex;
	flex-direction: column;
}

.container_data {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.container_description {
	display: flex;
	width: 70%;
	align-items: baseline;
	gap: 30px;
}


.sub_container {
	display: flex;
	flex-direction: column;
}

.text_title {
	margin-bottom: 6px;
	margin-top: 13px;
	color: #959595;
	font-size: 12px;
	font-weight: bold;
}

.description {
	display: flex;
	align-items: center;
}

.button {
	width: 134px;
	height: 40px;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	background: #2B80FF;
	font-size: 12px;
	color: #FFFFFF;
}

.button:hover {
	background: #FFFFFF;
	color: #2B80FF;
	border: 1px solid #2B80FF;
}

.text_color {
	margin-bottom: 0px;
	color: #172B4D;
	font-size: 12px;
}

.text_label {
	margin-bottom: 0px;
	color: #172B4D;
	font-size: 13px;
	font-weight: bold;
}

.text_name {
	margin-bottom: 0px;
	color: #2B80FF;
	font-weight: bold;
	font-size: 18px;
	cursor: pointer;
}

.conatiner_table {
	margin-top: 41px;
}

.text_date {
	color: #959595;
	font-size: 11px;
}

.button_table {
	background: transparent;
	color: #2B80FF;
	font-weight: bold;
	font-size: 12px;
}

@media(width >=1280px) {
	.text_title {
		font-size: 12px;
	}

	.button {
		font-size: 12px;
		width: 134px;
		height: 40px;
	}

	.icon_button {
		font-size: 14px;
	}

	.text_color {
		font-size: 12px;
	}

	.text_label {
		font-size: 13px;
	}

	.text_name {
		font-size: 18px;
	}

	.text_date {
		font-size: 11px;
	}

	.button_table {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.text_title {
		font-size: 13.5px;
	}

	.button {
		font-size: 13.5px;
		width: 150.75px;
		height: 45px;
	}

	.icon_button {
		font-size: 15.75px;
	}

	.text_color {
		font-size: 13.5px;
	}

	.text_label {
		font-size: 14.62px;
	}

	.text_name {
		font-size: 20.25px;
	}

	.text_date {
		font-size: 12.37px;
	}

	.button_table {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.text_title {
		font-size: 15.18px;
	}

	.button {
		font-size: 15.18px;
		width: 169.59px;
		height: 50.62px;
	}

	.icon_button {
		font-size: 17.71px;
	}

	.text_color {
		font-size: 15.18px;
	}

	.text_label {
		font-size: 16.44px;
	}

	.text_name {
		font-size: 22.78px;
	}

	.text_date {
		font-size: 13.92px;
	}

	.button_table {
		font-size: 15.18px;
	}
}