* {
	padding: 0;
	margin: 0;
}

.row__reScheduleForm {
	display: flex;
	align-items: center;
	flex-flow: row;
}

.column__reScheduleForm {
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.space-between {
	display: flex;
	justify-content: space-between;
}

.left__reScheduleForm,
.right__reScheduleForm {
	width: 49%
}

.row__reScheduleForm .title {
	color: #004085;
	font-size: 14px;
	font-weight: bold;
}

.row__reScheduleForm .blue_breadcum {
	color: #004085;
	margin-right: 15px;
	font-size: 12px;
}

.row__reScheduleForm .p {
	color: #004085;
	font-size: 12px;
	margin: inherit;
	padding: inherit;
}

.bottom_section__reScheduleForm .btn_normal {
	background: transparent;
	z-index: 10;
	border: none;
	outline: none;
	width: 83px;
	height: 34px;
	border: 1px solid #BFCCDA;
	border-radius: 3px;
	cursor: pointer;

	:hover {
		background: silver;
	}
}

.bottom_section__reScheduleForm .btn_silver {
	border: none;
	outline: none;
	color: #001737;
	z-index: 10;


	width: 83px;
	height: 34px;
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	cursor: pointer;
}

.bottom_section__reScheduleForm .btn_silver:hover {
	background: #2B80FF;
	color: white;
}

.bottom_section__reScheduleForm {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	z-index: 10;
	flex: 1;
}

.input_form_reagendar {
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.input_form_reagendar {
		font-size: 12px;
	}

	.row__reScheduleForm .title {
		font-size: 14px;
	}

	.row__reScheduleForm .blue_breadcum {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.input_form_reagendar {
		font-size: 13.5px;
	}

	.row__reScheduleForm .title {
		font-size: 15.75px;
	}

	.row__reScheduleForm .blue_breadcum {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.input_form_reagendar {
		font-size: 15.18px;
	}

	.row__reScheduleForm .title {
		font-size: 17.71px;
	}

	.row__reScheduleForm .blue_breadcum {
		font-size: 15.18px;
	}
}