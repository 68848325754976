.font_14 {
	font-size: 14px;
}

.color5A607F {
	color: #5A607F;
}

.pdl36_5 {
	padding-left: 36.5px;
}

.container_displacement {
	padding-top: 22px;
}

.container_table {
	margin-top: 30px;
}

@media(width >=1280px) {
	.font_14 {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.font_14 {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.font_14 {
		font-size: 17.17px;
	}
}