.container {
	display: flex;
	justify-content: space-between;
}

.sub_container {
	width: 45%;
}

.input {
	width: 80%;
	height: 40px;
}

.select {
	width: 100%;
	height: 40px;
}

.container_summary {
	display: flex;
	flex-direction: column;
}

.container_summary .subcontainer {
	display: flex;
	gap: 8px;
}

.title_summary {
	font-size: 12px;
	color: #131523;
	font-weight: bold;
}

.text_summary {
	font-size: 12px;
	color: #131523;
}

@media(width >=1280px) {
	.title_summary {
		font-size: 12px;
	}

	.text_summary {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.title_summary {
		font-size: 13.5px;
	}

	.text_summary {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.title_summary {
		font-size: 15.18px;
	}

	.text_summary {
		font-size: 15.18px;
	}
}