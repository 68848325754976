.bottom_section_form {
	width: 100%;
	min-height: 150px;
	background: white;
	gap: 8px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex: 1;
}

button {
	border: none;
	outline: none;
	cursor: pointer;
}

.bottom_section_form .cancel__btn {
	background: transparent;
	width: 83px;
	height: 34px;
	border: 1px solid #F53C56;
	border-radius: 3px;
	background: white;
	color: #F53C56;
	font-size: 12px;
}

.bottom_section_form .cancel__btn:hover {
	background: #F53C56;
	color: #FFFFFF;
}

.bottom_section_form .acept__btn {
	width: 83px;
	height: 34px;
	color: #5A607F;
	background: #EFEFEF;
	border: 1px solid #EFEFEF;
	border-radius: 3px;
	font-size: 12px;
}

.bottom_section_form .btn_disable_styles {
	width: 83px;
	height: 34px;
	color: #001737;
	background: #DDE4EB;
	border: 1px solid #DDE4EB;
	border-radius: 3px;
	font-size: 12px;
	cursor: default;
}

.bottom_section_form .btn_disable_styles:hover {
	background: #DDE4EB;
	border: 1px solid #DDE4EB;
}

.bottom_section_form .acept__btn:hover {
	background: #DCDCDC;
}

.bottom_section_form .disable_style {
	width: 83px;
	height: 34px;
	border: 1px solid #DDE4EB;
	border-radius: 3px;
	background: #DDE4EB;
	color: #001737;
	font-size: 12px;
	cursor: default;
}

@media screen and (min-width: 1280px) {

	.bottom_section_form .cancel__btn {
		width: 83px;
		height: 34px;
		font-size: 12px;
	}

	.bottom_section_form .acept__btn {
		width: 83px;
		height: 34px;
		font-size: 12px;
	}

	.bottom_section_form .btn_disable_styles {
		width: 83px;
		height: 34px;
		font-size: 12px;
	}

	.bottom_section_form .disable_style {
		width: 83px;
		height: 34px;
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {

	.bottom_section_form .cancel__btn {
		width: 93.37px;
		height: 38.25px;
		font-size: 13.5px;
	}

	.bottom_section_form .acept__btn {
		width: 93.37px;
		height: 38.25px;
		font-size: 13.5px;
	}

	.bottom_section_form .btn_disable_styles {
		width: 93.37px;
		height: 38.25px;
		font-size: 13.5px;
	}

	.bottom_section_form .disable_style {
		width: 93.37px;
		height: 38.25px;
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {

	.bottom_section_form .cancel__btn {
		width: 105.04px;
		height: 43.03px;
		font-size: 15.18px;
	}

	.bottom_section_form .acept__btn {
		width: 105.04px;
		height: 43.03px;
		font-size: 15.18px;
	}

	.bottom_section_form .btn_disable_styles {
		width: 105.04px;
		height: 43.03px;
		font-size: 15.18px;
	}

	.bottom_section_form .disable_style {
		width: 105.04px;
		height: 43.03px;
		font-size: 15.18px;
	}
}