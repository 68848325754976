.text {
	font-size: 12px;
	color: #959595;
}

.header_table {
	height: 32px;
	background: #F5F6FA;
	color: #8898AA;
	font-size: 12px;
}

.th_one {
	padding-left: 16px;
	width: 40%;
}

.th_two {
	width: 40%;
}

.body_table {
	background: rgba(184, 218, 255, 0.15);
	font-size: 12px;
}

.colum_category {
	width: 30%;
	padding-left: 16px;
	font-weight: bold;
	color: #172B4D;
}

td div:first-of-type {
	padding-top: 13px;
}

.span_table {
	margin-bottom: 15px;
	width: 100%;
	color: rgba(23, 43, 77, 0.74);
}

@media(width >=1280px) {
	.text {
		font-size: 12px;
	}

	.header_table {
		height: 32px;
		font-size: 12px;
	}

	.body_table {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.text {
		font-size: 13.5px;
	}

	.header_table {
		height: 36px;
		font-size: 13.5px;
	}

	.body_table {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.text {
		font-size: 15.18px;
	}

	.header_table {
		height: 40.5px;
		font-size: 15.18px;
	}

	.body_table {
		font-size: 15.18px;
	}
}