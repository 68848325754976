.container {
	padding: 34.33px 41.25px 5px 26.79px;
}

.container_question {
	display: flex;
	flex-direction: column;
	background: rgba(221, 228, 235, 0.15);
	border: 0.4px solid rgba(221, 228, 235, 0.35);
	border-radius: 5px;
	padding: 23.21px 55.4px 30px 29.18px;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 20px;
}

.flex_column {
	display: flex;
	flex-direction: column;
}

.button_modify {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 109px;
	height: 40px;
	background: #2B80FF;
	color: #ffffff;
	border-radius: 5px;
	gap: 5px;
}

.container_comment {
	background: rgba(221, 228, 235, 0.15);
	margin-bottom: 25px;
}

.container_questions {
	display: flex;
	align-items: center;
	height: 65px;
	background: #FFFFFF;
	margin-bottom: 25px;
	border-radius: 5px;
	padding-left: 26.6px;
	padding-right: 46.6px;
}

.container_questions label {
	margin: 0;
}

.container_questions_kind_two {
	display: flex;
	align-items: center;
	height: 108px;
	background: #FFFFFF;
	margin-bottom: 25px;
	border-radius: 5px;
	padding-left: 26.6px;
	padding-right: 46.6px;
}


.kind_one {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-right: 10px;
}

.container_switch {
	display: flex;
	align-items: center;
	gap: 10px
}

.kind_two {
	width: 100%;
	display: flex;
	flex-direction: column;
}

.input {
	height: 40px;
}


.font_12 {
	font-size: 12px;
}

.font_14 {
	font-size: 14px;
}

.color5A607F {
	color: #5A607F;
}

.color131523 {
	color: #131523;
}

.bold {
	font-weight: bold;
}

@media(width >=1280px) {

	.button_modify {
		width: 109px;
		height: 40px;
	}

	.font_12 {
		font-size: 12px;
	}

	.font_14 {
		font-size: 14px;
	}
}

@media(width >=1366px) {

	.button_modify {
		width: 122.62px;
		height: 45px;
	}

	.font_12 {
		font-size: 13.5px;
	}

	.font_14 {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {

	.button_modify {
		width: 137.95px;
		height: 50.62px;
	}

	.font_12 {
		font-size: 15.18px;
	}

	.font_14 {
		font-size: 17.71px;
	}
}