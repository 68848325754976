.view_plan_container {
	display: flex;
	width: 100%;
	min-height: 90px;
	background: #E9F4FF;
}

.view_plan_container .container_text_plan {
	width: 35%;
	display: flex;
	flex-direction: column;
	padding: 20px 26px;
}

.container_text_plan .text_plan {
	color: #172B4D;
	font-size: 12px;
	font-weight: bold;
	margin: 0;
}

.container_text_plan .text_plan_14 {
	color: #172B4D;
	font-size: 14px;
	font-weight: bold;
	margin: 0;
}

.container_text_plan .text_plan_blue {
	color: #2B80FF;
	font-size: 12px;
	font-weight: bold;
	margin: 0;
}

.view_plan_container .container_specification {
	display: flex;
	flex-direction: column;
	padding: 20px 10px;
	width: 64%;
}

.container_specification .title_specification {
	color: #435464;
	font-weight: bold;
	font-size: 11px;
	margin: 0;
}

.container_specification .text_characteristics {
	color: #5B5C65;
	font-size: 11px;
}

.container_specification .container_button {
	display: flex;
	justify-content: space-between;
	margin: 0;
}

.container_button .button_text {
	background: transparent;
	color: #2B80FF;
	font-size: 12px;
}

.container_button .button_selected {
	background: transparent;
	color: #2B80FF;
	font-weight: bold;
	font-size: 12px;
}

.container_specification .container_plan_specification {
	width: 65%;
	display: flex;
	justify-content: space-between;
	padding: 12px 0 0 0;
}

.container_plan_specification .arrow_center {
	background: #BCBCBC;
	color: white;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 8px;
}

.container_plan_specification .distribution_subcontainer {
	display: flex;
	align-items: center;
}

.distribution_subcontainer .text_plan_specification {
	color: #BCBCBC;
	font-size: 14px;
	margin: 0 8px;
}

.container_specification .container_television {
	width: 30%;
	display: flex;
	justify-content: space-between;
	padding: 12px 0 0 0;
}

.container_television .text_television {
	color: #BCBCBC;
	font-size: 14px;
}

.container_specification .container_telephony {
	padding: 12px 0 0 0;
}

.container_telephony .text_telephony {
	color: #BCBCBC;
	font-size: 14px;
}

@media screen and (min-width: 1280px) {

	.container_text_plan .text_plan {
		font-size: 12px;
	}

	.container_text_plan .text_plan_14 {
		font-size: 14px;
	}

	.container_text_plan .text_plan_blue {
		font-size: 12px;
	}

	.container_specification .title_specification {
		font-size: 11px;
	}

	.container_specification .text_characteristics {
		font-size: 11px;
	}

	.container_button .button_text {
		font-size: 12px;
	}

	.container_button .button_selected {
		font-size: 12px;
	}

	.container_plan_specification .arrow_center {
		font-size: 8px;
	}

	.distribution_subcontainer .text_plan_specification {
		font-size: 14px;
	}

	.container_television .text_television {
		font-size: 14px;
	}

	.container_telephony .text_telephony {
		font-size: 14px;
	}
}

@media screen and (min-width: 1366px) {

	.container_text_plan .text_plan {
		font-size: 13.5px;
	}

	.container_text_plan .text_plan_14 {
		font-size: 15.75px;
	}

	.container_text_plan .text_plan_blue {
		font-size: 13.5px;
	}

	.container_specification .title_specification {
		font-size: 12.375px;
	}

	.container_specification .text_characteristics {
		font-size: 12.375px;
	}

	.container_button .button_text {
		font-size: 13.5px;
	}

	.container_button .button_selected {
		font-size: 13.5px;
	}

	.container_plan_specification .arrow_center {
		font-size: 9px;
	}

	.distribution_subcontainer .text_plan_specification {
		font-size: 15.75px;
	}

	.container_television .text_television {
		font-size: 15.75px;
	}

	.container_telephony .text_telephony {
		font-size: 15.75px;
	}
}

@media screen and (min-width: 1920px) {

	.container_text_plan .text_plan {
		font-size: 15.18px;
	}

	.container_text_plan .text_plan_14 {
		font-size: 17.71px;
	}

	.container_text_plan .text_plan_blue {
		font-size: 15.18px;
	}

	.container_specification .title_specification {
		font-size: 13.92px;
	}

	.container_specification .text_characteristics {
		font-size: 13.92px;
	}

	.container_button .button_text {
		font-size: 15.18px;
	}

	.container_button .button_selected {
		font-size: 15.18px;
	}

	.container_plan_specification .arrow_center {
		font-size: 10.125px;
	}

	.distribution_subcontainer .text_plan_specification {
		font-size: 17.71px;
	}

	.container_television .text_television {
		font-size: 17.71px;
	}

	.container_telephony .text_telephony {
		font-size: 17.71px;
	}
}