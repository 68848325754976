.container_header {
	background: #f3f3f3;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: 110px;
}

.container_header .top {
	display: flex;
	justify-content: space-between;
}

.container_header .sub_container_header {
	display: flex;
	align-items: baseline;
}

.container_header .title_header {
	color: #001737;
	font-weight: bold;
	margin: 0;
	padding: 0;
	font-size: 24px;
}

.container_header .sub_text_header {
	color: #001737;
	font-size: 12px;
}

.button_dupli_OT {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 176px;
	height: 38px;
	background: #FFFFFF;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	color: #5A607F;
	font-size: 12px;
	gap: 5px;
}

@media(width >=1280px) {
	.container_header .title_header {
		font-size: 24px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_dupli_OT {
		width: 176px;
		height: 38px;
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.container_header .title_header {
		font-size: 27px;
	}

	.container_header .sub_text_header {
		font-size: 13.5px;
	}

	.button_dupli_OT {
		width: 198px;
		height: 42.75px;
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.container_header .title_header {
		font-size: 30.37px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_dupli_OT {
		width: 230.75px;
		height: 48.09px;
		font-size: 15.18px;
	}
}