.button_table {
	color: #2B80FF;
	background: transparent;
	font-weight: bold;
	font-size: 12px;
}

.conatiner_filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-right: 63px;
	padding-bottom: 29px;
}

.text {
	color: #5A607F;
	font-size: 14px;
	margin: 0;
	padding-left: 43px;
}

@media screen and (min-width: 1280px) {
	.button_table {
		font-size: 12px;
	}

	.text {
		font-size: 14px;
	}
}

@media screen and (min-width: 1366px) {
	.button_table {
		font-size: 13.5px;
	}

	.text {
		font-size: 15.75px;
	}
}

@media screen and (min-width: 1920px) {
	.button_table {
		font-size: 15.18px;
	}

	.text {
		font-size: 17.71px;
	}
}