.flex_column {
	display: flex;
	flex-direction: column;
}

.bold {
	font-weight: bold;
}

.textfont12 {
	font-size: 12px;
}

.color868D96 {
	color: #868D96;
}

.Checkbox {
	margin-top: 15px;
	margin-bottom: 18px;
	color: #2B80FF;
	font-weight: bold;
	font-size: 12px;
}

.container_switch {
	display: flex;
	gap: 12px;
	margin-top: 25px;
	align-items: baseline;
}

.subcontaine_switch {
	width: 100px;
	display: flex;
	justify-content: space-between;
}

.subcontaine_switch p {
	margin: 0;
}

.text_area {
	margin-bottom: 23px !important;
	height: 100px !important;
}

@media(width >=1280px) {
	.Checkbox {
		font-size: 12px;
	}

	.textfont12 {
		font-size: 12px;
	}

}

@media(width >=1366px) {
	.Checkbox {
		font-size: 13.5px;
	}

	.textfont12 {
		font-size: 13.5px;
	}
}

@media(width >=1280px) {
	.Checkbox {
		font-size: 15.18px;
	}

	.textfont12 {
		font-size: 15.18px;
	}
}