.container_header {
	display: flex;
	background: white;
	width: 100%;
	height: 65px;
	padding: 0 30px;
	justify-content: space-between;
}

.sub_container_left {
	height: 100%;
	display: flex;
	align-items: center;
}

.sub_container_right {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	position: relative;
}

.droppable_content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.user_dialog {
	width: 101.75px;
	height: 23px;
	border-radius: 5px;
	background: #0060f8;
	position: absolute;
	left: -115px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.etiqueta_azul {
	cursor: pointer;
	color: white;
	font-size: 12px;
}

.after {
	width: 10px;
	height: 10px;
	position: absolute;
	right: -10px;
	top: 6px;
	background: white;
	border-left: 7px solid #0060f8;
	border-top: 7px solid transparent;
	border-bottom: 7px solid transparent;
}

.header_avatar {
	width: 35px;
	height: 35px;
	line-height: 35px;
}

.user_name {
	width: 140px;
	display: flex;
	align-items: center;
	justify-content: center;
	text-transform: capitalize;
}

.text_name {
	font-size: 12px;
}

.visible_box {
	width: 165px;
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 10px 0;
	display: flex;
	flex-flow: column;
	background: white;
	position: absolute;
	bottom: -85px;
	user-select: none;
	z-index: 1000;
}

.search {
	width: 385px;
	margin-top: 12px;
	margin-left: -115px;
}

.logout_title {
	font-size: 13px;
	color: #868E96;
	padding: 0px 24px;
}

.logout_text {
	font-size: 12px;
	color: #131523;
}