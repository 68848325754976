.column__type_order_service {
    display: flex;
    flex-flow: column;
}

.align-item-center {
    display: flex;
    align-items: center;
}

.row-center {
    display: flex;
    justify-content: center;
}

.flex-end {
    display: flex;
    justify-content: flex-end;
}

.space-between {
    display: flex;
    justify-content: space-between;
}

.column_center {
    display: flex;
    justify-content: center;
}

// ------------------------------------------
// ------------------------------------------
.left__type_order_service,
.right__type_order_service {
    width: 49%;
}

.inputContainer__type_order_service {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 5px;
    border: 1px solid #E0E0E0;
    position: relative;
}

.inputContainer__type_order_service .selected_color {
    width: calc(99% - 40px);
    height: 40px;
    padding: 1em;
    display: flex;
    align-items: center;
}

.inputContainer__type_order_service .selected_color .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    margin-right: 10px;
}

.inputContainer__type_order_service .colorPickerIcon,
.inputContainer__type_order_service .colorPickerIcon svg {
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.inputContainer__type_order_service .colorPickerIcon svg {
    width: 15px;
    height: 15px;
    cursor: pointer;
    fill: #959595;
}

.inputColor {
    position: absolute
}