.my__custom__main__blue__button__styles {
    font-size: 12px;
    font-weight: 400;
    color: white;
    border: none;
    outline: none;
    width: 56px;
    background: #2B80FF;
    text-align: center;
    padding: 1px 3px;
}

@media screen and (min-width: 1280px) {
    .my__custom__main__blue__button__styles {
        font-size: 12px
    }
}

@media screen and (min-width: 1366px) {
    .my__custom__main__blue__button__styles {
        font-size: 13.5px;
    }
}

@media screen and (min-width: 1920px) {
    .my__custom__main__blue__button__styles {
        font-size: 15.18px;
    }
}