.container {
	width: 100%;
	background: #F9FAFB;
	border: 0.4px solid #F9FAFB;
	border-radius: 5px;
	padding: 23.4px 30.8px 31.9px 20.4px;
}

.container_header {
	display: flex;
	gap: 19px;
	margin-bottom: 18.4px;
}

.container_header .circle {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #2B80FF;
}

.container_header .text_header {
	font-size: 12px;
	color: #5A607F;
	margin: 0;
	width: 90%;
}

.container_separate {
	display: flex;
	margin-top: 10px;
	gap: 14.6px;
}

.container_separate .subcontainer_separate {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.container_separate .subcontainer_separate .two_input {
	display: flex;
	gap: 7px;
}

.message_error {
	color: #FF4D4F;
	font-size: 10px;
}

@media(width >=1280px) {

	.container_header .circle {
		width: 25px;
		height: 25px;
	}

	.container_header .text_header {
		font-size: 12px;
	}

	.message_error {
		font-size: 10px;
	}
}

@media(width >=1366px) {

	.container_header .circle {
		width: 28.12px;
		height: 28.12px;
	}

	.container_header .text_header {
		font-size: 13.5px;
	}

	.message_error {
		font-size: 11.25px;
	}
}

@media(width >=1920px) {

	.container_header .circle {
		width: 31.64px;
		height: 31.64px;
	}

	.container_header .text_header {
		font-size: 15.18px;
	}

	.message_error {
		font-size: 12.65px;
	}
}