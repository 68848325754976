.container {
	display: flex;
	width: 236px;
	min-height: 170px;
	background: white;
	position: absolute;
	top: 53px;
	z-index: 2;
	margin-left: -206px;
	border: 1px solid #E0E0E0;
	box-sizing: border-box;
	border-radius: 3px;
	display: flex;
	flex-direction: column;
}

.filter_container {
	padding: 15px 0 0 24px;
}

.title {
	font-size: 14px;
	color: #868E96;
	margin-bottom: 17px;
}

.conatiner_bottom {
	display: flex;
	flex-wrap: wrap;
	padding-left: 24px;
	padding-right: 19px;
	justify-content: space-between;
	align-items: baseline;
}

.text {
	font-size: 12px;
	color: #5A607F;
	margin-bottom: 13px;
}

.text:hover {
	color: #0060FF;
	cursor: pointer;
	font-weight: bold;
}

.button_ok {
	width: 26px;
	height: 24px;
	background: #DDE4EB;
	border-radius: 3px;
	margin-bottom: 8px;
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.title {
		font-size: 14px;
	}

	.text {
		font-size: 12px;
	}

	.button_ok {
		width: 26px;
		height: 24px;
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.title {
		font-size: 15.75px;
	}

	.text {
		font-size: 13.5px;
	}

	.button_ok {
		width: 29.25px;
		height: 27px;
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.title {
		font-size: 17.71px;
	}

	.text {
		font-size: 15.18px;
	}

	.button_ok {
		width: 32.9px;
		height: 30.37px;
		font-size: 15.18px;
	}
}