* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.row {
	display: flex;
	margin: 0 !important;
	align-items: center;
}

.column {
	display: flex;
	margin: 0 !important;
	flex-flow: column !important;
}

.row_center {
	display: flex;
	justify-content: center;
}

.switch_container {
	width: 100%;
	display: flex;
	background: white;
	margin-top: 20px;
	flex-direction: column;
}

.switch_box {
	padding-right: 30px;
}

.ant-picker {
	min-height: 40px;
}

.bottom_section {
	display: flex;
	justify-content: flex-end;
	z-index: 10;
}

.btn_normal {
	background: transparent;
	z-index: 10;

	:hover {
		background: silver;
	}
}

.btn_silver {
	background: #2B80FF;
	color: white;
	z-index: 10;

	:hover {
		background: #2B80FF;
	}
}

.errorRed {
	border: 1px solid red
}

.text_switch {
	margin-right: 15px;
	color: #131523;
	font-weight: bold;
}

.select_font_12 {
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.select_font_12 {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.select_font_12 {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.select_font_12 {
		font-size: 15.18px;
	}
}