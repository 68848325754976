.container_client {
	display: flex;
	gap: 16px;
	margin-bottom: 27px;
}

.container_client .text_ot_client {
	font-size: 12px;
	color: #131523;
	font-weight: bold;
	margin: 0;
}

.container_client .container_switch {
	width: 86px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container_client .container_switch .text_switch {
	font-size: 12px;
	color: #131523;
	margin: 0;
}

.container_type_client {
	display: flex;
	flex-direction: column;
	width: 45%;
	margin-bottom: 27px;
}


.container_category {
	display: flex;
	gap: 40px;
	margin-bottom: 17px;
}

.container_category .type {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 42px;
	background: #E9F4FF;
	border-radius: 2px;
	color: #131523;
	font-weight: bold;
	font-size: 22px;
	padding: 0 16px;
}

.container_category .category_subcontainer {
	display: flex;
	flex-direction: column;
}

.container_category .category_subcontainer .title {
	font-size: 12px;
	font-weight: bold;
	color: #172B4D;
	margin-bottom: 0;
}

.category_subcontainer .category_text {
	font-size: 14px;
	color: #959595;
}

.container_user {
	display: flex;
	background: #FFFFFF;
	border: 1px solid #7070702E;
	padding: 21.93px 32.52px 30px 15px;
	margin-top: 24px;
	margin-bottom: 31.44px;
}

.avatar_user {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background-color: #F53C56;
	font-size: 24px;
	font-weight: bold;
}

.description_user {
	display: flex;
	flex-direction: column;
	margin-left: 13.84px;
	width: 63%;
}

.user_name {
	font-size: 14px;
	color: #5A607F;
	margin: 0;
}

.user_text {
	font-size: 12px;
	color: #2B80FF;
	margin: 0;
}

.bold {
	font-weight: bold;
}

.container_assistant {
	display: flex;
	margin-top: 6px;
	margin-bottom: 14.85px;
}

.avatar_assistant {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	font-size: 12px;
	font-weight: bold;
	margin-right: 11px;
}

.text_font12_5A607F {
	font-size: 12px;
	color: #5A607F;
	margin: 0;
}

.text_font12_959595 {
	font-size: 12px;
	color: #959595;
	margin: 0;
}

.text_font12_2B80FF {
	font-size: 12px;
	color: #2B80FF;
	margin: 0;
}

.container_date {
	display: flex;
	flex-direction: column;
	margin-bottom: 42px;
}

.container_separate {
	display: flex;
	margin-top: 10px;
	gap: 14.6px;
}

.container_separate .subcontainer_separate {
	display: flex;
	flex-direction: column;
	width: 100%;
}

@media(width >=1280px) {
	.container_client .text_ot_client {
		font-size: 12px;
	}

	.container_client .container_switch .text_switch {
		font-size: 12px;
	}

	.container_category .type {
		/* width: 148px;
		height: 42px; */
		font-size: 22px;
	}

	.container_category .category_subcontainer .title {
		font-size: 12px;
	}

	.category_subcontainer .category_text {
		font-size: 14px;
	}

	.user_name {
		font-size: 14px;
	}

	.user_text {
		font-size: 12px;
	}

	.avatar_assistant {
		width: 20px;
		height: 20px;
		font-size: 12px;
	}

	.text_font12_5A607F {
		font-size: 12px;
	}

	.text_font12_959595 {
		font-size: 12px;
	}

	.text_font12_2B80FF {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.container_client .text_ot_client {
		font-size: 13.5px;
	}

	.container_client .container_switch .text_switch {
		font-size: 13.5px;
	}

	.container_category .type {
		/* width: 166, 5px;
		height: 47.25px; */
		font-size: 24.75px;
	}

	.container_category .category_subcontainer .title {
		font-size: 13.5px;
	}

	.category_subcontainer .category_text {
		font-size: 15.75px;
	}

	.user_name {
		font-size: 15.75px;
	}

	.user_text {
		font-size: 13.5px;
	}

	.avatar_assistant {
		width: 22.5px;
		height: 22.5px;
		font-size: 13.5px;
	}

	.text_font12_5A607F {
		font-size: 13.5px;
	}

	.text_font12_959595 {
		font-size: 13.5px;
	}

	.text_font12_2B80FF {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.container_client .text_ot_client {
		font-size: 15.18px;
	}

	.container_client .container_switch .text_switch {
		font-size: 15.18px;
	}

	.container_category .type {
		/* width: 187.3px;
		height: 53.15px; */
		font-size: 27.84px;
	}

	.container_category .category_subcontainer .title {
		font-size: 15.18px;
	}

	.category_subcontainer .category_text {
		font-size: 17.71px;
	}

	.user_name {
		font-size: 17.71px;
	}

	.user_text {
		font-size: 15.18px;
	}

	.avatar_assistant {
		width: 25.31px;
		height: 25.31px;
		font-size: 15.18px;
	}

	.text_font12_5A607F {
		font-size: 15.18px;
	}

	.text_font12_959595 {
		font-size: 15.18px;
	}

	.text_font12_2B80FF {
		font-size: 15.18px;
	}

}