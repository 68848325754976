.container_header {
	background: #f3f3f3;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: 110px;
}

.container_header .top {
	display: flex;
	justify-content: space-between;
}

.container_header .sub_container_header {
	display: flex;
	align-items: baseline;
}

.container_header .title_header {
	color: #001737;
	font-weight: bold;
	margin: 0;
	padding: 0;
	font-size: 24px;
}

.container_header .sub_text_header {
	color: #001737;
	font-size: 12px;
}

.button_header {
	width: 182px;
	height: 40px;
	border: 1px solid #2B80FF;
	background: #FFFFFF;
	color: #2B80FF;
	border-radius: 5px;
	font-size: 13px;
}

.button_header:hover {
	background: #2B80FF;
	color: #FFFFFF;
}

@media(width >=1280px) {
	.container_header .title_header {
		font-size: 24px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_header {
		width: 182px;
		height: 40px;
		font-size: 13px;
	}
}

@media(width >=1366px) {
	.container_header .title_header {
		font-size: 27px;
	}

	.container_header .sub_text_header {
		font-size: 13.5px;
	}

	.button_header {
		width: 204.75px;
		height: 45px;
		font-size: 14.625px;
	}
}

@media(width >=1920px) {
	.container_header .title_header {
		font-size: 30.37px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_header {
		width: 230.34px;
		height: 50.625px;
		font-size: 16.45px;
	}
}