.button_table {
	background: transparent;
	color: #2B80FF;
	font-weight: bold;
	font-size: 12px;
}

.container_add {
	display: flex;
	flex-direction: column;
	flex: 1;
}

.add_text {
	font-size: 12px;
	color: #131523;
}

@media(width >=1280px) {
	.button_table {
		font-size: 12px;
	}

	.add_text {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.button_table {
		font-size: 13.5px;
	}

	.add_text {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.button_table {
		font-size: 15.18px;
	}

	.add_text {
		font-size: 15.18px;
	}
}