.container_client {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	height: 89px;
	padding: 10px;
	border-radius: 5px;
	background: #FFFFFF;
	border: 0.5px solid #7070702E;
	margin-bottom: 19.7px;
}

.column {
	display: flex;
	flex-direction: column;
}

.group_left {
	display: flex;
	align-items: center;
}

.column_p_r {
	padding-right: 31.53px;
}

.text_client_name {
	font-size: 14px;
	color: #5A607F;
	font-weight: bold;
	margin-bottom: 0;
}

.text_client_document {
	font-size: 12px;
	color: #959595;
	margin-bottom: 0;
}

.text_client_type {
	font-size: 12px;
	color: #5A607F;
	font-weight: bold;
	margin-bottom: 0;
}

.text_client_service {
	font-size: 12px;
	color: #2B80FF;
	font-weight: bold;
	margin-bottom: 0;
}

.container_order {
	display: flex;
	flex-direction: column;
}

.date {
	display: flex;
	gap: 28px;
}

.text_date_title {
	font-size: 12px;
	color: #959595;
	font-weight: bold;
	margin-bottom: 0;
}

.text_date {
	font-size: 14px;
	color: #172B4D;
	margin-bottom: 0;
}

.type_order {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 192.86px;
	height: 47.5px;
	background: #E9F4FF;
	border-radius: 2px;
	font-size: 22px;
	color: #131523;
	font-weight: bold;
}

.container_type_category {
	display: flex;
	gap: 32px;
	margin-top: 14px;
}

.text_category_title {
	font-size: 12px;
	color: #172B4D;
	font-weight: bold;
	margin-bottom: 0;
}

.text_category {
	font-size: 14px;
	color: #959595;
	margin-bottom: 0;
}

.title_description {
	font-size: 12px;
	color: #5A607F;
	font-weight: bold;
	margin-bottom: 6px;
	margin-top: 16.5px;
}

.content_description {
	font-size: 12px;
	color: #959595;
	margin-bottom: 30.52px;
}

.container_address {
	display: flex;
	align-items: center;
	gap: 21px;
}

.avatar_address {
	background-color: #2B80FF;
	font-size: 14px;
	width: 30px;
	height: 30px;
}

.text_address {
	font-size: 14px;
	font-weight: bold;
	color: #5A607F;
	margin-bottom: 0;
}

.text_address_content {
	font-size: 14px;
	color: #4F4F4F;
	margin-bottom: 0;
}

.container_user {
	display: flex;
	background: #FFFFFF;
	border: 1px solid #7070702E;
	padding: 21.93px 32.52px 30px 15px;
	margin-top: 24px;
	margin-bottom: 31.44px;
}

.avatar_user {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background-color: #F53C56;
	font-size: 24px;
	font-weight: bold;
}

.description_user {
	display: flex;
	flex-direction: column;
	margin-left: 13.84px;
	width: 63%;
}

.user_name {
	font-size: 14px;
	color: #5A607F;
	margin: 0;
}

.user_text {
	font-size: 12px;
	color: #2B80FF;
	margin: 0;
}

.bold {
	font-weight: bold;
}

.container_assistant {
	display: flex;
	margin-top: 6px;
	margin-bottom: 14.85px;
}

.avatar_assistant {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	font-size: 12px;
	font-weight: bold;
	margin-right: 11px;
}

.text_font12_5A607F {
	font-size: 12px;
	color: #5A607F;
	margin: 0;
}

.text_font12_959595 {
	font-size: 12px;
	color: #959595;
	margin: 0;
}

.text_font12_2B80FF {
	font-size: 12px;
	color: #2B80FF;
	margin: 0;
}

.mb_12 {
	margin-bottom: 12.39px;
}

.checkbox {
	font-size: 12px;
	color: #131523;
	font-weight: bold;
	margin-top: 27.21px;
}

@media(width >=1280px) {
	.text_client_name {
		font-size: 14px;
	}

	.text_client_document {
		font-size: 12px;
	}

	.text_client_type {
		font-size: 12px;
	}

	.text_client_service {
		font-size: 12px;
	}

	.text_date_title {
		font-size: 12px;
	}

	.text_date {
		font-size: 14px;
	}

	.type_order {
		width: 192.86px;
		height: 47.5px;
		font-size: 22px;
	}

	.text_category_title {
		font-size: 12px;
	}

	.text_category {
		font-size: 14px;
	}

	.title_description {
		font-size: 12px;
	}

	.content_description {
		font-size: 12px;
	}

	.avatar_address {
		font-size: 14px;
		width: 30px;
		height: 30px;
	}

	.text_address {
		font-size: 14px;
	}

	.text_address_content {
		font-size: 14px;
	}

	.user_name {
		font-size: 14px;
	}

	.user_text {
		font-size: 12px;
	}

	.avatar_assistant {
		width: 20px;
		height: 20px;
		font-size: 12px;
	}

	.text_font12_5A607F {
		font-size: 12px;
	}

	.text_font12_959595 {
		font-size: 12px;
	}

	.text_font12_2B80FF {
		font-size: 12px;
	}

	.checkbox {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.text_client_name {
		font-size: 15.75px;
	}

	.text_client_document {
		font-size: 13.5px;
	}

	.text_client_type {
		font-size: 13.5px;
	}

	.text_client_service {
		font-size: 13.5px;
	}

	.text_date_title {
		font-size: 13.5px;
	}

	.text_date {
		font-size: 15.75px;
	}

	.type_order {
		width: 216.96px;
		height: 53.43px;
		font-size: 24.75px;
	}

	.text_category_title {
		font-size: 13.5px;
	}

	.text_category {
		font-size: 15.75px;
	}

	.title_description {
		font-size: 13.5px;
	}

	.content_description {
		font-size: 13.5px;
	}

	.avatar_address {
		font-size: 15.75px;
		width: 33.75px;
		height: 33.75px;
	}

	.text_address {
		font-size: 15.75px;
	}

	.text_address_content {
		font-size: 15.75px;
	}

	.user_name {
		font-size: 15.75px;
	}

	.user_text {
		font-size: 13.5px;
	}

	.avatar_assistant {
		width: 22.5px;
		height: 22.5px;
		font-size: 13.5px;
	}

	.text_font12_5A607F {
		font-size: 13.5px;
	}

	.text_font12_959595 {
		font-size: 13.5px;
	}

	.text_font12_2B80FF {
		font-size: 13.5px;
	}

	.checkbox {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.text_client_name {
		font-size: 17.71px;
	}

	.text_client_document {
		font-size: 15.18px;
	}

	.text_client_type {
		font-size: 15.18px;
	}

	.text_client_service {
		font-size: 15.18px;
	}

	.text_date_title {
		font-size: 15.18px;
	}

	.text_date {
		font-size: 17.71px;
	}

	.type_order {
		width: 244px;
		height: 60.11px;
		font-size: 27.84px;
	}

	.text_category_title {
		font-size: 15.18px;
	}

	.text_category {
		font-size: 17.71px;
	}

	.title_description {
		font-size: 15.18px;
	}

	.content_description {
		font-size: 15.18px;
	}

	.avatar_address {
		font-size: 17.71px;
		width: 37.96px;
		height: 37.96px;
	}

	.text_address {
		font-size: 17.71px;
	}

	.text_address_content {
		font-size: 17.71px;
	}

	.user_name {
		font-size: 17.71px;
	}

	.user_text {
		font-size: 15.18px;
	}

	.avatar_assistant {
		width: 25.31px;
		height: 25.31px;
		font-size: 15.18px;
	}

	.text_font12_5A607F {
		font-size: 15.18px;
	}

	.text_font12_959595 {
		font-size: 15.18px;
	}

	.text_font12_2B80FF {
		font-size: 15.18px;
	}

	.checkbox {
		font-size: 15.18px;
	}
}