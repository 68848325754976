.input_font_12_holidays {
	font-size: 12px;
}

@media screen and (min-width: 1280px) {

	.input_font_12_holidays {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {

	.input_font_12_holidays {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {

	.input_font_12_holidays {
		font-size: 15.18px;
	}
}