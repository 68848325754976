.container_information {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.box {
	width: 120%;
	height: 57%;
	display: flex;
}

.box .text_label {
	color: #5A607F;
	font-size: 14px;
	margin-bottom: 5px;
}

.box .text_asig {
	color: #2B80FF;
	font-size: 14px;
	font-weight: bold;
	margin-left: 5px;
}

.text_bold {
	font-weight: bold;
	margin-left: 5px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 45%;
}

.height_80 {
	height: 80%;
}

/* ------------------------------------- */
.box_right {
	width: 34%;
	height: 75%;
	background: #E9F4FF;
	border: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.box_right .text_date {
	font-size: 22px;
	font-weight: bold;
	color: #131523;
}

.container_hours {
	display: flex;
	flex-flow: column;
	margin-top: 22px;
}

.container_important {
	width: 100%;
	height: 105px;
	background: #F7FAFC;
	border: 1px solid #DDE4EB;
	display: flex;
	flex-flow: column;
}

.container_important .text_blue {
	padding-left: 15px;
	padding-top: 18px;
}

.container_important .checkbox {
	padding-left: 15px;
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.box {
		height: 57%;
	}

	.box .text_label {
		font-size: 14px;
	}

	.box .text_asig {
		font-size: 14px;
	}

	.box_right {
		width: 34%;
	}

	.box_right .text_date {
		font-size: 22px;
	}

	.container_important {
		height: 105px;
	}

	.container_important .checkbox {
		font-size: 12px;
	}

	.text_bold {
		width: 45%;
	}
}

@media screen and (min-width: 1366px) {
	.box {
		height: 60%;
	}

	.box .text_label {
		font-size: 15.75px;
	}

	.box .text_asig {
		font-size: 15.75px;
	}

	.box_right {
		width: 43%;
	}

	.box_right .text_date {
		font-size: 24.75px;
	}

	.container_important {
		height: 110px;
	}

	.container_important .checkbox {
		font-size: 13.5px;
	}

	.text_bold {
		width: 45%;
	}
}

@media screen and (min-width: 1920px) {
	.box {
		height: 65%;
	}

	.box .text_label {
		font-size: 17.71px;
	}

	.box .text_asig {
		font-size: 17.71px;
	}

	.box_right {
		width: 40%;
	}

	.box_right .text_date {
		font-size: 27.84px;
	}

	.container_important {
		height: 110px;
	}

	.container_important .checkbox {
		font-size: 15.18px;
	}
}

@media screen and (min-width: 1670px) {
	.text_bold {
		width: 50%;
	}
}

@media screen and (min-width: 1850px) {
	.text_bold {
		width: 53%;
	}
}