* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

.subtitleBlue {
	font-weight: bold;
	font-size: 14px;
	display: inline-flex;
}

@media screen and (min-width: 1280px) {

	.subtitleBlue {
		font-size: 14px;
	}
}

@media screen and (min-width: 1366px) {

	.subtitleBlue {
		font-size: 15.75px;
	}
}

@media screen and (min-width: 1920px) {

	.subtitleBlue {
		font-size: 17.71px;
	}
}