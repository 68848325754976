.container_dropdown {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.container_duration {
	margin-right: 30px;
}

/* .description_dutarution {
	margin: 0;
	font-weight: bold;
	font-size: 14px;
} */

.title_dutarution {
	margin: 0;
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
}