.container_history {
	width: 100%;
	display: flex;
	flex-flow: column;
	background: white;
	max-height: 670px;
	overflow-y: auto;
	padding-left: 45.69px;
	padding-top: 32.78px;
}

.container_dropdown {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 5px;
}

.drop_styling {
	width: 170px;
}

.container_duration {
	margin-right: 30px;
}

.title_dutarution {
	margin: 0;
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
}

.subtitle_history {
	font-size: 14px;
	color: #5A607F;
	margin-bottom: 30px;
}

@media(width >=1280px) {
	.title_dutarution {
		font-size: 14px;
	}

	.subtitle_history {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.title_dutarution {
		font-size: 15.75px;
	}

	.subtitle_history {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.title_dutarution {
		font-size: 17.71px;
	}

	.subtitle_history {
		font-size: 17.71px;
	}
}