.container_plan {
	display: flex;
	width: 100%;
	min-height: 90px;
	background: #E9F4FF;
}

.subcontainer_text_plan {
	width: 40%;
	display: flex;
	flex-direction: column;
	padding: 20px 26px;
}

.text_plan {
	color: #172B4D;
	font-size: 12px;
	font-weight: bold;
	margin: 0;
}

.container_specification {
	display: flex;
	flex-direction: column;
	padding: 20px 10px;
	width: 58%;
}

.title_specification {
	color: #435464;
	font-weight: bold;
	font-size: 11px;
	margin: 0;
}

.text_characteristics {
	color: #5B5C65;
	font-size: 11px;
}

.container {
	width: 100%;
	border: 0.5px solid #7070702E;
	border-radius: 5px;
	padding: 22px 25px 11px 16px;
}

.header {
	display: flex;
	justify-content: space-between;
}

.container_data {
	display: flex;
	gap: 30px
}

.subcontaier_data {
	display: flex;
	flex-direction: column;
	margin-bottom: 8px;
}

.subcontaier_data label {
	margin-bottom: 3px;
}

.text_name {
	font-size: 14px;
	color: #5A607F;
	font-weight: bold;
}

.text_document {
	font-size: 14px;
	color: #959595;
}

.text_title_direction {
	font-size: 12px;
	color: #5A607F;
	font-weight: bold;
}

.text_direction {
	font-size: 12px;
	color: #959595;
}

.text_number {
	font-size: 14px;
	color: #2B80FF;
	font-weight: bold;
}

.avatar {
	width: 50px;
	height: 50px;
	font-size: 18px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.text_plan_14 {
	color: #172B4D;
	font-size: 14px;
	font-weight: bold;
	margin: 0
}

.text_plan_blue {
	color: #2B80FF;
	font-size: 12px;
	font-weight: bold;
	margin: 0;
}

.container_button {
	display: flex;
	justify-content: space-between;
	margin: 0;
}

.subcontainer_button {
	display: flex;
	flex-direction: column;
}

.button_selected {
	background: transparent;
	color: #2B80FF;
	font-weight: bold;
	font-size: 12px;
}

.button_text {
	background: transparent;
	color: #2B80FF;
	font-size: 12px;
}

.linea {
	width: 70%;
	height: 5px;
	margin: auto;
	background: #2B80FF;
	border-radius: 5px;
	position: relative;
	overflow: hidden;
}

.container_plan_specification {
	display: flex;
	padding: 12px 0 0 0;
	gap: 10px;
}

.distribution_subcontainer {
	display: flex;
	align-items: center;
}

.arrow_center {
	background: #BCBCBC;
	color: white;
	width: 15px;
	height: 15px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 8px;
}

.text_plan_specification {
	color: #BCBCBC;
	font-size: 14px;
	margin: 0 8px;
}

.container_television {
	width: 30%;
	display: flex;
	justify-content: space-between;
	padding: 12px 0 0 0;
}

.text_television {
	color: #BCBCBC;
	font-size: 14px;
}

.container_telephony {
	padding: 12px 0 0 0;
}

.text_telephony {
	color: #BCBCBC;
	font-size: 14px;
}

@media(width >=1280px) {

	.text_plan {
		font-size: 12px;
	}

	.title_specification {
		font-size: 11px;
	}

	.text_characteristics {
		font-size: 11px;
	}

	.text_name {
		font-size: 14px;
	}

	.text_document {
		font-size: 14px;
	}

	.text_title_direction {
		font-size: 12px;
	}

	.text_direction {
		font-size: 12px;
	}

	.text_number {
		font-size: 14px;
	}

	.avatar {
		width: 50px;
		height: 50px;
		font-size: 18px;
	}

	.text_plan_14 {
		font-size: 14px;
	}

	.text_plan_blue {
		font-size: 12px;
	}

	.button_selected {
		font-size: 12px;
	}

	.button_text {
		font-size: 12px;
	}

	.arrow_center {
		font-size: 8px;
	}

	.text_plan_specification {
		font-size: 14px;
	}

	.text_television {
		font-size: 14px;
	}

	.text_telephony {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.text_plan {
		font-size: 13.5px;
	}

	.title_specification {
		font-size: 12.37px;
	}

	.text_characteristics {
		font-size: 12.37px;
	}

	.text_name {
		font-size: 15.75px;
	}

	.text_document {
		font-size: 15.75px;
	}

	.text_title_direction {
		font-size: 13.5px;
	}

	.text_direction {
		font-size: 13.5px;
	}

	.text_number {
		font-size: 13.5px;
	}

	.avatar {
		width: 56.25px;
		height: 56.25px;
		font-size: 20.25px;
	}

	.text_plan_14 {
		font-size: 15.75px;
	}

	.text_plan_blue {
		font-size: 13.5px;
	}

	.button_selected {
		font-size: 13.5px;
	}

	.button_text {
		font-size: 13.5px;
	}

	.arrow_center {
		font-size: 9px;
	}

	.text_plan_specification {
		font-size: 15.75px;
	}

	.text_television {
		font-size: 15.75px;
	}

	.text_telephony {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.text_plan {
		font-size: 15.18px;
	}

	.title_specification {
		font-size: 13.91px;
	}

	.text_characteristics {
		font-size: 13.91px;
	}

	.text_name {
		font-size: 17.71px;
	}

	.text_document {
		font-size: 17.71px;
	}

	.text_title_direction {
		font-size: 15.18px;
	}

	.text_direction {
		font-size: 15.18px;
	}

	.text_number {
		font-size: 15.18px;
	}

	.avatar {
		width: 63.28px;
		height: 63.28px;
		font-size: 22.78px;
	}

	.text_plan_14 {
		font-size: 17.71px;
	}

	.text_plan_blue {
		font-size: 15.18px;
	}

	.button_selected {
		font-size: 15.18px;
	}

	.button_text {
		font-size: 15.18px;
	}

	.arrow_center {
		font-size: 10.12px;
	}

	.text_plan_specification {
		font-size: 17.71px;
	}

	.text_television {
		font-size: 17.71px;
	}

	.text_telephony {
		font-size: 17.71px;
	}
}