.workday__tooltip__container {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
}

.workday__tooltip__container .tittle {
    color: #868E96;
    font-size: 14px;
    padding: 6px 0 12px 6px;
}

.workday__tooltip__container .row__content {
    display: flex;
    align-items: center;
    width: 100%;
    padding-left: 5px
}
.workday__tooltip__container .row__content .description {
    color: #172B4D;
    font-size: 12px;
}