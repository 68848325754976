.datatableHeaderText {
	font-size: 14px;
	display: inline-block;
}

@media screen and (min-width: 1280px) {
	.datatableHeaderText {
		font-size: 14px;
	}
}

@media screen and (min-width: 1366px) {

	.datatableHeaderText {
		font-size: 15.75px;
	}
}

@media screen and (min-width: 1920px) {

	.datatableHeaderText {
		font-size: 17.71px;
	}
}