.users-mainPage-container {
	padding-top: 34.57px;
	display: flex;
	flex-flow: column;
}

.users-mainPage-container .single-pText {
	color: #5A607F;
	font-size: 14px;
	margin: 0 !important;
	padding: 0 !important;
	margin-bottom: 27.56px !important;
	padding-left: 42px !important;
	width: 128%;
}

.users-mainPage-container .ant-table-filter-trigger {
	margin-right: 42px;
	margin-left: 31px;
}

// padding para el primer box title
.users-mainPage-container .ant-table-column-has-sorters {
	padding-left: 27px !important;
}

// -----------------------------
// -----------------------------

.users-mainPage-container .filter-container {
	background: white;
	width: 95%;
	margin-bottom: 15px;
	display: flex;
	flex-flow: column;
}

.users-mainPage-container .filter-container .row {
	display: flex;
	align-items: center;
	margin: inherit;
	padding: inherit;
}

.users-mainPage-container .filter-container .row .input-search-box-content {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

.users-mainPage-container .filter-container .subtitle-filter {
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
}



.users-mainPage-container .filter-container .ant-input-wrapper {
	height: 40px !important;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	background: white;
}


.users-mainPage-container .filter-container .ant-input-affix-wrapper {
	display: flex;
	align-items: center;
	height: 40px;
	padding: 0;
	background: white;
	border: none;
	outline: none;
}

.users-mainPage-container .filter-container .ant-input {
	width: 100%;
	height: 30px;
	background: white;
	padding: 4px 11px;
	padding-left: 11px !important;
	background: white;
	font-size: 12px;
}


.users-mainPage-container .filter-container .ant-input-search>.ant-input-group>.ant-input-group-addon:last-child .ant-input-search-button {
	height: 40px;
	border: none;
	outline: none;
	background: white;
}

.users-mainPage-container .filter-container .ant-input-affix-wrapper:focus,
.users-mainPage-container .filter-container .ant-input-affix-wrapper-focused {
	box-shadow: none;
	background: white;
}

.users-mainPage-container .filter-container .ant-btn.ant-btn-icon-only {
	width: 25px;
	padding-inline-start: 0;
	padding-inline-end: 0;
	background: white;
}

.title-list-users {
	color: #001737;
	font-weight: bold;
	font-size: 24px;
}

.circle-tooltip_users {
	margin: 0 10px;
	color: #00388B;
	font-size: 12px;
}

.sub-tex-list-users {
	color: #001737;
	font-weight: 400;
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.users-mainPage-container .filter-container .ant-input {
		font-size: 12px;
	}

	.users-mainPage-container .single-pText {
		font-size: 14px;
	}

	.title-list-users {
		font-size: 24px;
	}

	.circle-tooltip_users {
		font-size: 12px;
	}

	.sub-tex-list-users {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.users-mainPage-container .filter-container .ant-input {
		font-size: 13.5px;
	}

	.users-mainPage-container .single-pText {
		font-size: 15.75px;
	}

	.title-list-users {
		font-size: 27px;
	}

	.circle-tooltip_users {
		font-size: 13.5px;
	}

	.sub-tex-list-users {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.users-mainPage-container .filter-container .ant-input {
		font-size: 15.18px;
	}

	.users-mainPage-container .single-pText {
		font-size: 17.71px;
	}

	.title-list-users {
		font-size: 30.37px;
	}

	.circle-tooltip_users {
		font-size: 15.18px;
	}

	.sub-tex-list-users {
		font-size: 15.18px;
	}
}