.absences-mainPage-container {
	display: flex;
	flex-flow: column;
	padding-top: 34.57px;
}

.absences-mainPage-container .single-pText {
	color: #5A607F;
	font-size: 14px;
	margin: 0 !important;
	padding: 0 !important;
	margin-bottom: 27.56px !important;
	padding-left: 42px !important;
}

// --------TABLA ANTD--------
// --------icono onFilter--------
.absences-mainPage-container .ant-table-filter-trigger {
	margin-right: 42px;
}

.title_absence {
	color: #001737;
	font-weight: bold;
	font-size: 24px;
}

.circle-tooltip {
	margin: 0 10px;
	color: #00388B;
	font-size: 12px;
}

.sub_text_absences {
	color: #001737;
	font-weight: 400;
	font-size: 12px;
}


@media screen and (min-width: 1280px) {

	.input_font_form_absences {
		font-size: 12px;
	}

	.title_absence {
		font-size: 24px;
	}

	.circle-tooltip {
		font-size: 12px;
	}

	.sub_text_absences {
		font-size: 12px;
	}

	.absences-mainPage-container .single-pText {
		font-size: 14px;
	}
}

@media screen and (min-width: 1366px) {

	.input_font_form_absences {
		font-size: 13.5px;
	}

	.title_absence {
		font-size: 27px;
	}

	.circle-tooltip {
		font-size: 13.5px;
	}

	.sub_text_absences {
		font-size: 13.5px;
	}

	.absences-mainPage-container .single-pText {
		font-size: 15.75px;
	}
}

@media screen and (min-width: 1920px) {

	.input_font_form_absences {
		font-size: 15.18px;
	}

	.title_absence {
		font-size: 30.37px;
	}

	.circle-tooltip {
		font-size: 15.18px;
	}

	.sub_text_absences {
		font-size: 15.18px;
	}

	.absences-mainPage-container .single-pText {
		font-size: 17.71px;
	}
}