.workday__header__calendar__container {
    width: 100%;
    height: 60px;
    background: white;
    display: flex;
    align-items: center;
    margin-top: 31px;
    margin-bottom: 27px;
    padding: 0 22px;

}

.workday__header__calendar__container .customHeader__left,
.workday__header__calendar__container .customHeader__mid,
.workday__header__calendar__container .customHeader__right {
    flex: 1;
    background: white;
}

// ----------------------------------
.workday__header__calendar__container .customHeader__left .total__title {
    color: #1458E8;
    font-size: 14px;
    margin-right: 12px;
}

.workday__header__calendar__container .customHeader__left .total__box {
    color: white;
    background: #0060F8;
    width: 40px;
    height: 23px;
    margin-left: 12;
    padding: 4px 8px
}

// ----------------------------------

.workday__header__calendar__container .customHeader__mid {
    display: flex;
    justify-content: center;
    align-items: center;
}

.workday__header__calendar__container .customHeader__mid .label {
    margin: 0 16px;
    color: #5A607F;
    font-weight: bold;
    font-size: 18px;
}

.workday__header__calendar__container .customHeader__mid button {
    border: none;
    outline: none;
    background: white;
    color: #2B80FF;
    font-weight: bold;
    font-size: 18px;
}

// ----------------------------------

.workday__header__calendar__container .customHeader__right {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.workday__header__calendar__container .customHeader__right .date__btn {
    background: white;
    border-radius: 3px;
    color: #2B80FF;
    font-size: 12px;
    padding: 10px 17px;
    cursor: pointer;
}

.workday__header__calendar__container .customHeader__right .date__selected {
    background: #DDE4EB;
    border-radius: 3px;
    color: #131523;
    font-size: 12px;
    padding: 10px 17px;
    cursor: pointer;
}

// -------------
// -------------

// smallDesk: "1280px"
// mediumDesk: "1366px"
// largeDesk: "1920px"

@media screen and (min-width: 1280px) {
    .workday__header__calendar__container .customHeader__left .total__title,
    .workday__header__calendar__container .customHeader__mid .label,
    .workday__header__calendar__container .customHeader__right .date__btn,
    .workday__header__calendar__container .customHeader__right .date__selected {
        font-size: 18px;
    }
}

@media screen and (min-width:1366px) {
    .workday__header__calendar__container .customHeader__mid .label{font-size: 20.25px}
    .workday__header__calendar__container .customHeader__left .total__title,
    .workday__header__calendar__container .customHeader__right .date__btn,
    .workday__header__calendar__container .customHeader__right .date__selected {font-size: 13.5px}
    .select-calendar {width: 168.75px}
}

@media screen and (min-width: 1920px) {
    .workday__header__calendar__container .customHeader__mid .label {font-size: 22.78px}
    .workday__header__calendar__container .customHeader__left .total__title,
    .workday__header__calendar__container .customHeader__right .date__btn,
    .workday__header__calendar__container .customHeader__right .date__selected {font-size:15.18px}
    .select-calendar {width:189.84px}
}

