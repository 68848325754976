.container_switch {
	display: flex;
	align-items: baseline;
	margin-bottom: 15px;
}

.title_switch {
	font-size: 12px;
	font-weight: bold;
	color: #131523;
	margin-right: 11px !important;
}

.text_swicth {
	font-size: 12px;
	color: #131523;
}

.subcontainer_switch {
	display: flex;
	align-items: center;
}

.container_inpt_select {
	display: flex;
	flex-direction: column;
	margin-bottom: 19px;
}

.error {
	border: 1px dashed red !important;
}



@media(width >=1280px) {
	.title_switch {
		font-size: 12px;
	}

	.text_swicth {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.title_switch {
		font-size: 13.5px;
	}

	.text_swicth {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.title_switch {
		font-size: 15.18px;
	}

	.text_swicth {
		font-size: 15.18px;
	}
}