.title_period {
	font-size: 12px;
	color: #131523;
}

.container_select {
	margin-top: 11px;
}

@media screen and (min-width: 1280px) {
	.title_period {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.title_period {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.title_period {
		font-size: 15.18px;
	}
}