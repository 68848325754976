.container_filter {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-bottom: 29px;
	padding-right: 63px;
}

.container_empty {
	background: white;
}

.container_img {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: 539px;
	justify-content: center;
}

.title_empty {
	font-size: 20px;
	color: #5A607F;
	margin-bottom: 11px;
}

.text_p {
	margin: 0 !important;
	font-size: 14px;
	color: #5A607F;
}

.color_blue {
	color: #2B80FF;
}

.container_empty_search {
	padding-top: 34.57px;
	padding-left: 42px;
}

.text_list {
	font-size: 14px;
	color: #5A607F;
}

@media(width >=1280px) {
	.title_empty {
		font-size: 20px;
	}

	.text_p {
		font-size: 14px;
	}

	.text_list {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.title_empty {
		font-size: 22.5px;
	}

	.text_p {
		font-size: 15.75px;
	}

	.text_list {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.title_empty {
		font-size: 25.31px;
	}

	.text_p {
		font-size: 17.71px;
	}

	.text_list {
		font-size: 17.71px;
	}
}