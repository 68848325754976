.container {
	width: 100%;
	background: #F9FAFB;
	border: 0.4px solid #F9FAFB;
	border-radius: 5px;
	padding: 23.4px 30.8px 31.9px 20.4px;
}

.container_header {
	display: flex;
	gap: 19px;
	margin-bottom: 18.4px;
}

.container_header .circle {
	width: 25px;
	height: 25px;
	border-radius: 50%;
	background: #2B80FF;
}

.container_header .text_header {
	font-size: 12px;
	color: #5A607F;
	margin: 0;
	width: 90%;
}

.container_select {
	display: flex;
	flex-direction: column;
	width: 100%;
	margin-top: 15px;
}

.checkbox {
	font-size: 12px;
	color: #2B80FF;
	font-weight: bold;
	margin-top: 12px;
}

.container_coment_aditional {
	display: flex;
	gap: 16px;
	margin-bottom: 9px;
	margin-top: 14px;
}

.container_coment_aditional .text_coment_aditional {
	font-size: 12px;
	color: #131523;
	font-weight: bold;
	margin: 0;
}

.container_coment_aditional .container_switch {
	width: 86px;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.container_coment_aditional .container_switch .text_switch {
	font-size: 12px;
	color: #131523;
	margin: 0;
}

@media(width >=1280px) {

	.container_header .circle {
		width: 25px;
		height: 25px;
	}

	.container_header .text_header {
		font-size: 12px;
	}

	.checkbox {
		font-size: 12px;
	}

	.container_coment_aditional .text_coment_aditional {
		font-size: 12px;
	}

	.container_coment_aditional .container_switch .text_switch {
		font-size: 12px;
	}
}

@media(width >=1366px) {

	.container_header .circle {
		width: 28.12px;
		height: 28.12px;
	}

	.container_header .text_header {
		font-size: 13.5px;
	}

	.checkbox {
		font-size: 13.5px;
	}

	.container_coment_aditional .text_coment_aditional {
		font-size: 13.5px;
	}

	.container_coment_aditional .container_switch .text_switch {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {

	.container_header .circle {
		width: 31.64px;
		height: 31.64px;
	}

	.container_header .text_header {
		font-size: 15.18px;
	}

	.checkbox {
		font-size: 15.18px;
	}

	.container_coment_aditional .text_coment_aditional {
		font-size: 15.18px;
	}

	.container_coment_aditional .container_switch .text_switch {
		font-size: 15.18px;
	}
}