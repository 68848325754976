.container_header {
	width: 100%;
	height: 60px;
	background: white;
	display: flex;
	align-items: center;
	margin-bottom: 40px;
	padding: 25px 63px 5px 30.25px;
	margin-top: 10px;
}

.left {
	flex: 1;
	background: white;
}

.button_go_back {
	width: 85px;
	height: 21px;
	background: white;
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mid {
	flex: 1;
	background: white;
	display: flex;
	justify-content: center;
	align-items: center;
}

.button_mid {
	background: white;
	color: #2B80FF;
	font-weight: bold;
	font-size: 18px;
}

.label_mid {
	margin: 0 16px;
	color: #5A607F;
	font-weight: bold;
	font-size: 18px;
}

.right {
	flex: 1;
	background: white;
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.date_selected {
	background: #DDE4EB;
	border-radius: 3px;
	color: #2B80FF;
	font-size: 12px;
	padding: 10px 17px;
	cursor: pointer;
}

.date_btn {
	background: white;
	border-radius: 3px;
	color: #2B80FF;
	font-size: 12px;
	padding: 10px 17px;
	cursor: pointer;
}