.contaner_search {
	width: 385px;
	max-height: 385px;
	background: white;
	position: absolute;
	z-index: 1;
	border: 1px solid #e0e0e0;
	border-radius: 3px;
	padding: 17px 16px;
	overflow: scroll;
	overflow-x: hidden;
}

.text_blue {
	font-size: 12px;
	font-weight: bold;
	color: #2B80FF;
}

.container_category {
	display: flex;
	flex-direction: column;
	margin-top: 15.5px;
}

.category {
	display: flex;
	align-items: center;
	margin-bottom: 12.5px;
}

.category p {
	margin-bottom: 0;
	font-size: 11px;
}

.circle {
	display: flex;
	width: 10px;
	height: 10px;
	border-radius: 100%;
	margin-right: 5px;
}

.description_search {
	display: flex;
	justify-content: space-between;
}

.description_search p {
	margin-bottom: 0;
}

.document_container {
	display: flex;
	gap: 4px
}

.text_first {
	display: flex;
	gap: 10px;
}

.text_font {
	font-size: 11px;
}

.container_select .text_color {
	color: #5A607F;
}

.container_select {
	cursor: pointer;
	margin-bottom: 11px;
}

.container_select:hover {
	color: #2B80FF;
	font-weight: bold;
}


.container_select:hover .text_color {
	color: #2B80FF;
	font-weight: bold;
}

.position_container_search {
	position: relative;
	z-index: 15;
}