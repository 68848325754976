.left {
	background: white;
	padding-left: 33px;
}

.arrow_back {
	display: flex;
	align-items: center;
	background: white;
}

.arrow {
	color: #2B80FF;
	margin-right: 10px;
}

.availability_information {
	background: white;
	padding-left: 33px;
	padding-top: 25.21px;
	display: flex;
	flex-direction: column;
}

.registration_date {
	color: #959595;
	font-size: 11px;
}

.description_availability {
	padding-left: 33px;
	display: flex;
	gap: 260px;
	margin-bottom: 37px;
}

.container_column {
	display: flex;
	flex-direction: column;
}

.container_blue {
	width: 147.81px;
	height: 41.5px;
	background: #E9F4FF;
	border-radius: 2px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 22px;
	font-weight: bold;
}

.title {
	font-size: 12px;
	font-weight: bold;
	color: #172B4D;
	margin: 0;
}

.id_register {
	font-size: 14px;
	color: #959595;
	margin: 0;
}

.container_button {
	display: flex;
	gap: 10px;
}

.button_cancel {
	width: 85px;
	height: 34px;
	background: #FFFFFF;
	border: 1px solid #BFCCDA;
	border-radius: 3px;
	box-sizing: border-box;
	font-size: 12px;
}

.button_cancel:hover {
	background: #BFCCDA;
}

.button_continue {
	width: 85px;
	height: 34px;
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	box-sizing: border-box;
	font-size: 12px;
}

.button_continue:hover {
	background: #2B80FF;
	color: white
}

.section_description {
	display: flex;
	gap: 80px;
}


@media screen and (min-width: 1280px) {
	.registration_date {
		font-size: 11px;
	}

	.container_blue {
		font-size: 22px;
	}

	.title {
		font-size: 12px;
	}

	.id_register {
		font-size: 14px;
	}

	.button_cancel {
		width: 85px;
		height: 34px;
		font-size: 12px;
	}

	.button_continue {
		width: 85px;
		height: 34px;
		font-size: 12px;
	}

}

@media screen and (min-width: 1366px) {
	.registration_date {
		font-size: 12.375px;
	}

	.container_blue {
		font-size: 24.75px;
	}

	.title {
		font-size: 13.5px;
	}

	.id_register {
		font-size: 15.75px;
	}

	.button_cancel {
		width: 95.62px;
		height: 38.25px;
		font-size: 13.5px;
	}

	.button_continue {
		width: 95.62px;
		height: 38.25px;
		font-size: 13.5px;
	}

}

@media screen and (min-width: 1920px) {
	.registration_date {
		font-size: 13.92px;
	}

	.container_blue {
		font-size: 27.84px;
	}

	.title {
		font-size: 15.18px;
	}

	.id_register {
		font-size: 17.71px;
	}

	.button_cancel {
		width: 107.57px;
		height: 43px;
		font-size: 15.18px;
	}

	.button_continue {
		width: 107.57px;
		height: 43px;
		font-size: 15.18px;
	}

}