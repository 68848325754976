.container {
	width: 100%;
	padding-bottom: 31.9px;
}

.container_separate {
	display: flex;
	margin-top: 10px;
	gap: 14.6px;
}

.container_separate .subcontainer_separate {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.container_separate .subcontainer_separate .two_input {
	display: flex;
	gap: 7px;
}

.message_error {
	color: #FF4D4F;
	font-size: 10px;
}

@media(width >=1280px) {

	.message_error {
		font-size: 10px;
	}
}

@media(width >=1366px) {


	.message_error {
		font-size: 11.25px;
	}
}

@media(width >=1920px) {

	.message_error {
		font-size: 12.65px;
	}
}