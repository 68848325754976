.container_imagen {
	display: flex;
	gap: 10px;
	margin-top: 17px;
	align-items: flex-end;
	margin-bottom: 24.5px;
}

.container_imagen .container_input {
	display: flex;
	flex-direction: column;
	width: 81%;
}

.container_imagen .container_input .label_title {
	font-size: 12px;
	color: #131523;
	margin-bottom: 11px;
}

.container_imagen .button {
	width: 91px;
	height: 38px;
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	color: #001737;
	font-size: 12px;
}

.container_imagen .button:hover {
	color: #FFFFFF;
	background: #0060FF;
}

.container_questions {
	display: flex;
	gap: 10px;
	margin-top: 17px;
	align-items: flex-end;
	margin-bottom: 24.5px;
}

.container_questions .container_input {
	display: flex;
	flex-direction: column;
	width: 49%;
}

.container_questions .container_input .label_title {
	font-size: 12px;
	color: #131523;
	margin-bottom: 11px;
}

.container_questions .container_select {
	display: flex;
	flex-direction: column;
	width: 30%;
}

.container_questions .container_select .label_title {
	font-size: 12px;
	color: #131523;
	margin-bottom: 11px;
}

.container_questions .button {
	width: 91px;
	height: 38px;
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	color: #001737;
	font-size: 12px;
}

.container_questions .button:hover {
	color: #FFFFFF;
	background: #0060FF;
}

.container_add_img {
	display: flex;
	justify-content: space-between;
	padding: 0px 8px 5px 11px;
	align-items: baseline;
}

.container_add_img .text_label {
	font-size: 11px;
	color: #5A607F;
	margin: 0;
}

.container_add_img .button {
	background: transparent;
	color: #0060FF;
	font-size: 12px;
	font-weight: bold;
}

.container_add_questions {
	display: flex;
	justify-content: space-between;
	padding: 0 8px 5px 11px;
	align-items: baseline;
}

.container_add_questions .text_label_questions {
	font-size: 11px;
	color: #5A607F;
	margin: 0;
	width: 30%;
}

.container_add_questions .text_label {
	font-size: 11px;
	color: #5A607F;
	margin: 0;
}

.container_add_questions .button {
	background: transparent;
	color: #0060FF;
	font-size: 12px;
	font-weight: bold;
}

.container_button {
	display: flex;
	width: 120px;
	justify-content: space-between;
}

@media(width >=1280px) {
	.container_imagen .container_input .label_title {
		font-size: 12px;
	}

	.container_imagen .button {
		width: 91px;
		height: 38px;
		font-size: 12px;
	}

	.container_questions .container_input .label_title {
		font-size: 12px;
	}

	.container_questions .container_select .label_title {
		font-size: 12px;
	}

	.container_questions .button {
		width: 91px;
		height: 38px;
		font-size: 12px;
	}

	.container_add_img .text_label {
		font-size: 11px;
	}

	.container_add_img .button {
		font-size: 12px;
	}

	.container_add_questions .text_label_questions {
		font-size: 11px;
	}

	.container_add_questions .text_label {
		font-size: 11px;
	}

	.container_add_questions .button {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.container_imagen .container_input .label_title {
		font-size: 13.5px;
	}

	.container_imagen .button {
		width: 102.37px;
		height: 42.75px;
		font-size: 13.5px;
	}

	.container_questions .container_input .label_title {
		font-size: 13.5px;
	}

	.container_questions .container_select .label_title {
		font-size: 13.5px;
	}

	.container_questions .button {
		width: 102.37px;
		height: 42.75px;
		font-size: 13.5px;
	}

	.container_add_img .text_label {
		font-size: 12.37px;
	}

	.container_add_img .button {
		font-size: 13.5px;
	}

	.container_add_questions .text_label_questions {
		font-size: 12.37px;
	}

	.container_add_questions .text_label {
		font-size: 12.37px;
	}

	.container_add_questions .button {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.container_imagen .container_input .label_title {
		font-size: 15.18px;
	}

	.container_imagen .button {
		width: 115.17px;
		height: 48.09px;
		font-size: 15.18px;
	}

	.container_questions .container_input .label_title {
		font-size: 15.18px;
	}

	.container_questions .container_select .label_title {
		font-size: 15.18px;
	}

	.container_questions .button {
		width: 115.17px;
		height: 48.09px;
		font-size: 15.18px;
	}

	.container_add_img .text_label {
		font-size: 13.92px;
	}

	.container_add_img .button {
		font-size: 15.18px;
	}

	.container_add_questions .text_label_questions {
		font-size: 13.92px;
	}

	.container_add_questions .text_label {
		font-size: 13.92px;
	}

	.container_add_questions .button {
		font-size: 15.18px;
	}
}