.type-services-mainPage-container {
    display: flex;
    flex-flow: column;
}

// ----------
// ----------
.type-services-mainPage-container .time-await-content {
    display: flex;
    width: 100%;
    padding-left: 42px !important;
    padding-top: 23.57px !important;
}

.type-services-mainPage-container .time-await-content .edit_input {
    margin: inherit;
    width: 40px;
    height: 23px;
    border: none;
    outline: none;
    background: #0058FF;
    color: white;
    font-weight: 500;
}

.type-services-mainPage-container .time-await-content .minutes_input {
    margin: inherit;
    width: 40px;
    height: 23px;
    border: none;
    outline: none;
    background: #0058FF;
    color: white;
    font-weight: 500;
    font-size: 16px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    padding: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.type-services-mainPage-container .time-await-content .button {
    height: 23px;
    padding: 10px;
    background: #0058FF;
    border: none;
    outline: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

// ----------
// ----------

.type-services-mainPage-container .single-pText {
    color: #5A607F;
    font-size: 14px;
    margin: 0 !important;
    padding: 0 !important;
    margin-bottom: 27.56px !important;
    padding-left: 42px !important;
}

.type-services-mainPage-container .time-await-content .time-await-text {
    color: #1458E8;
    font-size: 14px;
    font-weight: 400;
    margin-right: 15px;
    border-bottom: 1px solid #1458E8;
}

.main__title__type__Service__orders {
    color: #001737;
    font-weight: bold;
    font-size: 24px
}

.sub__text__header__type__Service__orders {
    color: #001737;
    font-weight: 400;
    font-size: 12px
}

// ------------- Media querys -------------
@media screen and (min-width: 1280px) {
    .type-services-mainPage-container .single-pText {
        font-size: 14px
    }

    .type-services-mainPage-container .time-await-content .time-await-text {
        font-size: 13.66px
    }

    .main__title__type__Service__orders {
        font-size: 24px;
    }

    .sub__text__header__type__Service__orders {
        font-size: 12px;
    }
}

@media screen and (min-width: 1366px) {
    .type-services-mainPage-container .single-pText {
        font-size: 15.75px;
    }

    .type-services-mainPage-container .time-await-content .time-await-text {
        font-size: 15px
    }

    .main__title__type__Service__orders {
        font-size: 27px
    }

    .sub__text__header__type__Service__orders {
        font-size: 13.5px
    }
}

@media screen and (min-width: 1920px) {
    .type-services-mainPage-container .single-pText {
        font-size: 17.71px;
    }

    .type-services-mainPage-container .time-await-content .time-await-text {
        font-size: 15.18px;
    }

    .main__title__type__Service__orders {
        font-size: 30.37px;
    }

    .sub__text__header__type__Service__orders {
        font-size: 15.18px;
    }
}