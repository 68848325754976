.title_route_report {
	color: #001737;
	font-weight: bold;
	font-size: 24px;
}

.circle_tooltip {
	margin: 0 10px;
	color: #00388B;
	font-size: 12px;
}

.sub_text_route_report {
	color: #001737;
	font-weight: 400;
	font-size: 12px;
}

.date_compare_route_report {
	color: #001737;
	font-weight: 400;
	font-size: 12px;
	flex: 1;
	text-align: right;
}

.container_route {
	width: 60%;
	position: relative;
	z-index: 1;
}

.data_route {
	position: absolute;
	width: 88%;
	height: 95px;
	top: 31px;
	left: 36px;
	background: #F7FAFC;
	border: 1px solid #DDE4EB;
	z-index: 1000;
	opacity: .7;
	display: flex;
}

.sub_container_data {
	display: flex;
	width: 167px;
	height: 45px;
	margin: 32px 6px 0 23px;
	align-items: baseline;
}

.title_blue {
	font-size: 14px;
	font-weight: bold;
	color: #2B80FF;
	margin: 0;
}

.distance_traveled {
	display: flex;
	flex-direction: column;
	margin-left: 7px;
	align-items: baseline;
}

.icon {
	font-size: 14px;
}

.text_data {
	font-size: 12px;
	color: #172B4D;
}

.container_history {
	width: 44%;
	background: #FFFFFF;
	height: auto;
	border: 1px solid #DDE4EB;
	box-shadow: 0px 2px 3px 3px rgba(21, 34, 50, 0.08);
	z-index: 2;
	display: flex;
	flex-direction: column;
	padding: 28px 38px 0 25px;
}

.select_technicials {
	display: flex;
	flex-direction: column;
}

.date_route {
	display: flex;
	align-items: baseline;
	justify-content: space-between;
}

.timeline_route {
	margin-top: 32px;
	max-height: 290px;
	overflow-y: auto;
}

.scroll_item {
	overflow-x: auto;
	overflow-y: hidden;
	cursor: grab;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

.scroll_item::-webkit-scrollbar {
	display: none;
}

.dragging {
	cursor: grabbing;
}

.sub_container_average {
	display: flex;
	width: 180px;
	height: 45px;
	margin: 32px 6px 0 23px;
	align-items: baseline;
}

@media screen and (min-width: 1280px) {
	.title_route_report {
		font-size: 24px;
	}

	.circle_tooltip {
		font-size: 12px;
	}

	.sub_text_route_report {
		font-size: 12px;
	}

	.date_compare_route_report {
		font-size: 12px;
	}

	.title_blue {
		font-size: 14px;
	}

	.icon {
		font-size: 14px;
	}

	.text_data {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.title_route_report {
		font-size: 27px;
	}

	.circle_tooltip {
		font-size: 13.5px;
	}

	.sub_text_route_report {
		font-size: 13.5px;
	}

	.date_compare_route_report {
		font-size: 13.5px;
	}

	.title_blue {
		font-size: 15.75px;
	}

	.icon {
		font-size: 15.75px;
	}

	.text_data {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.title_route_report {
		font-size: 30.375px;
	}

	.circle_tooltip {
		font-size: 15.18px;
	}

	.sub_text_route_report {
		font-size: 15.18px;
	}

	.date_compare_route_report {
		font-size: 15.18px;
	}

	.title_blue {
		font-size: 17.71px;
	}

	.icon {
		font-size: 17.71px;
	}

	.text_data {
		font-size: 15.18px;
	}
}