.input_search_plan {
	height: 40px;
}

.container_search_plan {
	width: 100%;
	background: white;
	border-radius: 3px;
	border: 1px solid #E0E0E0;
	padding: 15px;
	overflow-y: scroll;
	max-height: 200px;
	height: auto;
}

.container_search_plan .subcontainer_search_plan {
	cursor: pointer;
	margin-bottom: 11px;
	width: 70%
}

.container_search_plan .subcontainer_search_plan:hover {
	color: #2B80FF;
	font-weight: bold;
}

.subcontainer_search_plan .subspan_text {
	display: flex;
	justify-content: space-between;
	width: 100%;
	margin: 0;
}

.subcontainer_search_plan .text_right_search {
	width: 40%;
	text-align: left;
}