.container_name {
	display: flex;
	flex-direction: column;
	width: 100%;
}

.container_date_type {
	display: flex;
	width: 100%;
	justify-content: space-between;
}

.subcontainer_date {
	display: flex;
	flex-direction: column;
	width: 48%;
}

.container_technicial {
	display: flex;
	flex-direction: column;
}

.text_hability {
	margin-right: 16px;
	font-size: 12px;
}

.container_button {
	width: 100%;
	min-height: 150px;
	gap: 8px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex: 1;
}

.delete_button {
	background: transparent;
	width: 110px;
	height: 34px;
	border: 1px solid #BFCCDA;
	border-radius: 3px;
	color: #001737;
	font-size: 12px;
}

.delete_button:hover {
	background: #BFCCDA
}

.save_button {
	width: 83px;
	height: 34px;
	color: #001737;
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	font-size: 12px;
}

.save_button:hover {
	background: #2B80FF;
	color: white
}