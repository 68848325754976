.container_tooltip {
	display: flex;
	flex-direction: column;
}

.tittle_tooltip {
	color: #868E96;
	font-size: 14px;
	margin-bottom: 15px;
}

.description_tooltip {
	color: #172B4D;
	font-size: 12px;
	width: 155px;
}

.container {
	display: flex;
	flex-flow: column;
	padding-top: 24.43px;
}

.container_filter {
	padding-left: 42.19px;
	padding-right: 53.31px;
}

.input_filter {
	display: flex;
	padding-bottom: 40.7px;
}

.select {
	width: 190px;
}

.flex {
	display: flex;
	gap: 12px;
	flex: 1;
}

.container_pagination {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 40.87px 41px 58px 40px;
}

.pagination {
	display: flex;
	color: #001737;
}

.pagination_fontsize {
	font-size: 12px;
}