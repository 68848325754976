* {
	box-sizing: border-box !important;
	font-family: 'Lato', sans-serif;
}

html,
body {
	margin: 0;
	padding: 0;
	max-width: 100vw !important;
	box-sizing: border-box !important;
	overflow-x: hidden;
	background: #f3f3f3
}

.text-header {
	text-align: center;
	color: rgb(237, 237, 237);
}

.active__btn {
	background: hsla(234, 87%, 45%, 0.822);
	font-weight: bold;
}

.active__li {
	background: white;
	color: white;
}

.grey__bg {
	background: #f3f3f3 !important;
	padding: 0 !important;
}


.content {
	background: #f3f3f3 !important;
	box-sizing: border-box !important;
}

// --------------------------------------
// --------------------------------------
.custom__select {
	width: 200px;
	height: 40px;
	background: white;
	color: grey;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	cursor: pointer;
	border-radius: 2px;
	border: 1px solid #d9d9d9;
	padding: 4px 11px 4px;

	:focus {
		border-color: #40a9ff;
		border-right-width: 1px;
	}
}

.DropDownList {
	width: 100%;
	padding: 1em 1em 0 1em;
	background: hsla(0, 0%, 100%, 0.7);
	backdrop-filter: blur(2px);
	color: grey;
	font-size: 16px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	position: absolute;
	top: 40px;
	left: 0;
	right: 0;
}

.ListItem {
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-bottom: 1em;
	color: black;
	color: white;
}

.panel-body {
	background: white;
	// medidas tomadas de Oraculo, archivo custom.scss
	padding: 3.5em 5em 1em 2.5em !important;
}


// -------------ANT-TABLE---------------------
// -------------------------------------------
.table {
	width: 100%;
	min-height: 100% !important;
	margin-bottom: 0;
	color: #333;
}

.ant-table-wrapper .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before,
:where(.css-dev-only-do-not-override-w8mnev).ant-table-wrapper .ant-table-thead>tr>td:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	position: absolute;
	top: 50%;
	inset-inline-end: 0;
	width: 1px;
	height: 1.6em;
	background-color: transparent;
	transform: translateY(-50%);
	transition: background-color 0.2s;
	content: "";
}

.ant-table-thead>tr>th {
	position: relative;
	color: #8898AA;
	font-weight: 400 !important;
	text-align: center;
	// background: #F5F6FA;
	border-bottom: none;
	transition: background 0.3s ease;
}

.ant-table-tbody>tr>td {
	border-bottom: none;
	transition: background 0.3s;
	// cursor: pointer;
}

.ant-table-thead tr th:first-child {
	padding-left: 42px !important;
}

// -------------

// primer columna vertical de IDs(todos)
.ant-table-tbody tr td:first-child {
	padding-left: 42px;
}

// 	background primer fila
.ant-table-row:nth-child(odd) {
	background: white;
	min-height: 75px;
}

// background siguiente fila
.ant-table-row:nth-child(even) {
	background: #F7FAFC;
	min-height: 75px;
	// cursor: pointer;
}

.ant-table-wrapper .ant-table-tbody>tr>th,
.ant-table-wrapper .ant-table-tbody>tr>td {
	border: none;
}

// quitar borde bottom de todas las filas.
:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>th,
:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-thead>tr>td {
	border: none;
	border-bottom: none;
}

// background para el primer box title 
:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-container table>thead>tr:first-child>* {
	background: #F5F6FA;
}

:where(.css-dev-only-do-not-override-byeoj0).ant-table-wrapper .ant-table-container table>thead>tr:first-child>*:first-child {
	padding-left: 42px !important;
}

// ---------------------------------
// --------REACT BIG CALENDAR-------
.rbc-time-view {
	display: flex;
	flex-direction: column;
	flex: 1 1;
	width: 100%;
	border: none;
	min-height: 0;
}

.rbc-timeslot-group {
	border-bottom: 1px solid #EAF0F4;
	min-height: 49px;
	display: flex;
	flex-flow: column nowrap;
	justify-content: center;
	align-items: center;
}

.rbc-time-content>*+*>* {
	border-left: 1px solid #EAF0F4;
}

.rbc-time-header.rbc-overflowing {
	border-right: 1px solid #EAF0F4;
	border: none;
}

.rbc-time-header-cell {
	height: 49px;
	display: flex;
	align-items: center;
}

.rbc-time-view .rbc-allday-cell {
	box-sizing: content-box;
	width: 100%;
	height: 100%;
	position: relative;
	display: none;
}

.rbc-time-header-content {
	flex: 1 1;
	display: flex;
	min-width: 0;
	flex-direction: column;
	border-left: 1px solid #EAF0F4;
	background: #F5F6FA;
}

.rbc-month-header {
	display: flex;
	flex-direction: row;
	background: #F5F6FA;
	height: 45px;
	align-items: center;
	color: #5A607F;
}

.rbc-time-content {
	display: flex;
	flex: 1 0;
	align-items: flex-start;
	width: 100%;
	border-top: none;
	overflow-y: auto;
	position: relative;
}

.rbc-day-slot .rbc-events-container {
	bottom: 0;
	left: 0;
	position: absolute;
	right: 0;
	margin-right: 0;
	top: 0;
}

.rbc-button-link {
	color: inherit;
	background: none;
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	cursor: pointer;
	-webkit-user-select: text;
	user-select: text;
	color: #5A607F;
	font-weight: bold;
	font-size: 12px;
}


.rbc-today {
	background-color: transparent;
}

.rbc-header {
	padding-bottom: 10px;
}

.rbc-event {
	border: 1px solid white
}

.rbc-btn-group button {
	background-image: none;
	box-shadow: none;
	background-color: white;
	border: none;
	outline: none;
	color: #2B80FF;
	padding: 10px 20px;
	font-size: 12px;
}

.rbc-btn-group button:active:hover,
.rbc-btn-group button:active:focus,
.rbc-btn-group button.rbc-active:hover,
.rbc-btn-group button.rbc-active:focus {
	background-image: none;
	border-color: none;
	box-shadow: none;
	color: #373a3c;
	background-color: #DDE4EB;
	border-radius: 3px;
	padding: 10px 20px;
	font-size: 12px;
}

.rbc-toolbar button:active,
.rbc-toolbar button:active:focus,
.rbc-toolbar button:active:hover,
.rbc-toolbar button.rbc-active {
	background-image: none;
	box-shadow: none;
	background-color: #DDE4EB;
	color: #373a3c;
	border-color: none;
}

.rbc-toolbar button:hover {
	background-image: none;
	box-shadow: none;
	background-color: white;
	border-color: none;
}

.rbc-header {
	border: none !important;
}

// ------VISTA DE MES/OFF------
.rbc-off-range-bg {
	background: white;
}

.rbc-day-bg {
	height: 135px;
}

.rbc-button-link {
	color: #8898AA;
	font-size: 15px;
	margin-top: 17px;
	margin-right: 22.65px;
}

// ------VISTA DE MES/OFF------

.rbc-current-time-indicator {
	border: 1px solid #11CDEF;
	// position: relative;
}


.rbc-event {
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: center;
	border-radius: 4px !important;
	// border: 1px solid white !important;
}

.rbc-day-slot .rbc-event-content {
	width: auto;
	height: auto;
	flex: none;
	word-wrap: break-word;
	border-radius: 4px !important;
}

.rbc-current-time-indicator::after {
	position: absolute;
	content: "";
	width: 8px;
	height: 8px;
	border-radius: 100%;
	left: -2px;
	bottom: -4px;
	background: #11CDEF;
	margin: 0;
	padding: 0;
	z-index: 10;
}

// -------------------
// --------FONTS------

.rbc-calendar,
.rbc-header,
.rbc-toolbar,
.rbc-agenda-view {
	font-family: 'Lato', sans-serif;
}

// -------------------
// --------FONTS------
.rbc-time-slot {
	display: flex;
	justify-content: center;
	align-items: flex-end;
	color: #8898AA;
	text-transform: uppercase;
}

.notification {
	width: 425px;

	.ant-notification-notice-message {
		font-size: 15px;
		color: #5a607f;
		font-weight: bold;
	}
}

.ant-popconfirm-title {
	font-size: 14px;
	color: white;
	font-weight: 600;
}

// estilo custom, para el nombre del tecnico en , Listado de técnicos, ver API
#table_blue_link {
	color: #2B80FF !important;
	font-weight: bold;
	font-size: 12px;
	border: none;
	outline: none;
	background: transparent;
	cursor: pointer;
}

#table_blue_link:hover {
	border-bottom: 1px solid #2B80FF !important;
}

// ---------------
// ---------------
.ant-pagination-item-active {
	font-weight: 600;
	background: #0058FF !important;
	border-color: #0058FF;
	color: white;
	border-radius: 4px;
}

// -------------------------
// -------------------------

// input-multiple (ITEM)
.ant-select-multiple .ant-select-selection-item {
	position: relative;
	display: flex;
	flex: none;
	box-sizing: border-box;
	max-width: 100%;
	height: 24px;
	margin-top: 2px;
	margin-bottom: 2px;
	line-height: 22px;
	background: white;
	border: 1px solid rgba(5, 5, 5, 0.06);
	border-radius: 4px;
	cursor: default;
	transition: font-size 0.3s, line-height 0.3s, height 0.3s;
	user-select: none;
	margin-inline-end: 4px;
	padding-inline-start: 8px;
	padding-inline-end: 4px;
}

// icono para eliminar de los inout- multiple
.ant-select-multiple .ant-select-selection-item-remove {
	display: flex;
	align-items: center;
	justify-content: center;
}

.ant-select-selector {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	padding: 1px 4px;
	border-radius: 6px;
	height: 40px;
}

.ant-input {
	width: 100%;
	// height: 40px;
	margin-bottom: 0;
	text-align: inherit;
}

.error__message {
	color: #DC3545;
	font-size: 12px;
}

.custom_scroll {
	&::-webkit-scrollbar {
		width: 10px;
		height: 6px;
	}

	&::-webkit-scrollbar-track {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.1);
	}

	&::-webkit-scrollbar-thumb {
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.2);
	}

	&::-webkit-scrollbar-thumb:hover {
		background: rgba(0, 0, 0, 0.4);
	}

	&::-webkit-scrollbar-thumb:active {
		background: rgba(0, 0, 0, 0.9);
	}
}

.my__Popconfirm__color__text__message {
	color: white;
}

// ----------------------Sidebar--------------------------------

.new__sidebar {
	.nav>li.active>a i {
		color: var(--secondary-color);
	}
}

.sidebar_background {
	background-image: linear-gradient(to top, #0f2441 0%, #1d4188 100%);
	margin: 0 !important;
	padding: 0 !important;
}

.sidebar .nav>li.active__btn:before {
	content: "";
	position: absolute;
	left: 0px;
	top: 5px;
	bottom: 10px;
	width: 5px;
	background: white;
	display: block;
}

.active__li {
	background: white;
	color: white;
}

.sidebar .nav>li>a,
ul .d-block>li>a {
	padding: 7px 20px 14px;
	line-height: 25px;
	color: white;
	display: block;
	text-decoration: none;
	font-size: 15px;

	:hover {
		color: rgba(255, 255, 255, 0.6);
	}
}

.sidebar .nav>li .no__link__text {
	font-weight: bold;
	padding: 25px 20px 15px;
	line-height: 25px;
	display: block;
	color: white;
}

.ul__row--center {
	display: flex;
	align-items: center;
}

.ul__row--center .text {
	flex: 1;
}

.sidebar .nav>li .no__hover {
	padding: 7px 20px 14px;
	line-height: 25px;
	color: rgba(255, 255, 255, 0.6);
	display: block;
	text-decoration: none;
	font-size: 15px;
}

.ul__row--center .dots {
	cursor: pointer;
	color: white;
	line-height: 0;

	:hover {
		color: rgba(255, 255, 255, 0.6);
	}
}

.ul__row--center .icon {
	display: flex;
	justify-content: center;
	align-items: center;
	background: white;
	border-radius: 3px;
	margin-right: 9px;
}

.ul__row--center .icon i {
	color: #0e3175;
}

.ul__row--center .text {
	color: white;
	text-decoration: none;
}

ul .d-block {
	padding-left: 8%;
	border-left: none !important;
	border: none !important;
	list-style-type: none;
	color: rgba(255, 255, 255, 0.6);
}

// style Select Options Sidebar

.container_operator {
	display: flex;
	flex-flow: column;
	width: 77%;
}

.container_operator .text_name_operator {
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	width: 100%;
}

.img_operator {
	width: 35px;
	height: 35px;
	margin-right: 10px;
	margin-left: 6px;
	border-radius: 100%;
}

.hover-table-class:hover {
	text-decoration: underline;
	text-decoration-color: #2B80FF;
}

@media screen and (min-width: "1280px") {

	.sidebar .nav>li>a,
	ul .d-block>li>a {
		font-size: 16px;
	}

	.sidebar .nav>li .no__link__text {
		font-size: 14px;
	}

	.ul__row--center .icon {
		width: 15px;
		height: 15px;
	}

	.ul__row--center .icon i {
		font-size: 10px;
	}

	.ul__row--center .text,
	.ul__row--center .dots i {
		font-size: 16px;
	}
}

@media screen and (min-width: "1366px") {

	.sidebar .nav>li>a,
	ul .d-block>li>a {
		font-size: 18px;
	}

	.sidebar .nav>li .no__link__text {
		font-size: 15.75px;
	}

	.ul__row--center .icon {
		width: 16.87px;
		height: 16.87px;
	}

	.ul__row--center .icon i {
		font-size: 11.25px;
	}

	.ul__row--center .text,
	.ul__row--center .dots i {
		font-size: 18px;
	}
}

@media screen and (min-width: "1920px") {

	.sidebar .nav>li>a,
	ul .d-block>li>a {
		font-size: 20.25px;
	}

	.sidebar .nav>li .no__link__text {
		font-size: 17.71px;
	}

	.ul__row--center .icon {
		width: 18.97px;
		height: 18.97px;
	}

	.ul__row--center .icon i {
		font-size: 12.65px;
	}

	.ul__row--center .text,
	.ul__row--center .dots i {
		font-size: 20.25px;
	}
}

// ---------datepicker pane--------------------------
.ant-picker-dropdown .ant-picker-cell .ant-picker-cell-inner {
	line-height: 0px !important;
}