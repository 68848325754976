.text_blue {
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
}

@media(width >=1280px) {
	.text_blue {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.text_blue {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.text_blue {
		font-size: 17.71px;
	}
}