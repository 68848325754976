.datatableRowText {
	font-size: 12px;
	display: inline-block;
	margin-bottom: 10px;
}

@media screen and (min-width: 1280px) {
	.datatableRowText {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {

	.datatableRowText {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {

	.datatableRowText {
		font-size: 15.18px;
	}
}