// background: hsla(0, 0%, 0%, 0.5);

p {
	display: inline;
	margin: inherit;
}

.row__technician__create {
	margin: inherit;
	display: flex;
	align-items: center;
	margin-top: 10px;
}

.row__technician__create .column {
	display: flex;
	flex-flow: column;
}

.row__technician__create .align-item-center {
	align-items: center;
}

.row__technician__create .row-center {
	justify-content: center;
}

.row__technician__create .center {
	display: flex;
	align-items: center;
	justify-content: center;
}

.row__technician__create .flex-end {
	justify-content: flex-end;
}

.row__technician__create .space-between {
	justify-content: space-between;
}

.row__technician__create .column_center {
	justify-content: center;
}

// input text nomal de ant
.ant-input {
	margin: 0;
	padding: 0;
	width: 100%;
	padding: 4px 11px;
	color: #868D96;
	font-size: 14px;
	background-color: white;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	transition: all 0.3s;
}

// input group de ant
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
	background-color: white;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
	height: 40px;
}

.row__technician__create .phoneFlagsSelect {
	width: 75px;
	height: 40px;
	background: white;
	border-radius: 5px;
	border: 1px solid #E0E0E0;
	display: flex;
	justify-content: center;
	align-items: center;
}

// select ant;
.irNypR .ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border: 1px solid #E0E0E0;
}

.ant-select-dropdown .ant-select-item {
	position: relative;
	display: block;
	min-height: 32px;
	padding: 5px 12px;
	color: #868D96;
	font-weight: normal;
	font-size: 12px;
	line-height: 1.5714285714285714;
	box-sizing: border-box;
	cursor: pointer;
	transition: background 0.3s ease;
	border-radius: 4px;
}

.ant-select-arrow {
	color: #343A40
}

.select {
	width: 100%;
	height: 40px;
	background: white;
	color: #868D96;
	border: 1px solid #E0E0E0;
	border-radius: 5px;

	padding: 6px 11px 6px;

}

.row__technician__create .input__phoneNumber {
	width: calc(97% - 75px) !important;
	height: 40px;
	border: none;
	outline: none;
	border-radius: 5px;
	border: 1px solid #E0E0E0;
	color: #868D96;
	padding-left: 11px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}

.row__technician__create .left,
.row__technician__create .right {
	width: 49%
}

.row__technician__create .add_button {
	width: 91px;
	height: 40px;
	background: #DDE4EB;
	border: none;
	outline: none;

	border: 1px solid #0060FF;
	border-radius: 3px;
	color: #001737;
	font-size: 13px;
	text-align: center;

	:hover {
		background: #2C91FC;
		color: white;
	}
}

.delete_noBtn {
	font-size: 12px;
	color: #2B80FF;
	font-weight: bold;
	border: none;
	outline: none;
	margin: 2px 0;
	background: white
}

// date picker ant
.ant-picker {
	color: #868D96;
	font-size: 12px;
	padding: 6px 11px 6px;
	background: white;
	border: 1px solid #E0E0E0;
	border-radius: 5px;
	transition: border 0.3s, box-shadow 0.3s;
}

.input_form_technicials {
	font-size: 12px;
}

.prefixe_form-technicials {
	color: #868D96;
	margin-left: 7px;
}

// --------
// --------
.container__new__technician__form__page .select__custom__style {
	width: 100%;
	min-height: 40px !important;
}

.container__new__technician__form__page .select__custom__style .ant-select-selector {
	width: 100%;
	height: auto !important;
}

.container__new__technician__form__page .ant-select-multiple .ant-select-selection-overflow {
	display: flex;
	flex-wrap: wrap;
	max-width: 100%;
}


.container__new__technician__form__page .ant-select-multiple .ant-select-selection-item {
	display: flex;
	align-items: center;
	padding-bottom: 5px;
}

.container__new__technician__form__page .ant-select-item-option {
	display: flex;
	flex-direction: row;
}

.container_role_form {
	margin-bottom: 15px;
}

.container_technicials_role_form {
	// margin-top: 10px;
	display: flex;
	flex-direction: column;
}

.container_important {
	min-height: 85px;
	width: 100%;
	display: flex;
	flex-direction: column;
	background: rgb(247, 250, 252);
	border: 1px solid rgb(221, 228, 235);
	justify-content: center;
	padding-left: 21px;
}

.title_important {
	margin-top: 17px;
}

// --------

@media screen and (min-width: 1280px) {
	.ant-select-dropdown .ant-select-item {
		font-size: 12px;
	}

	.input_form_technicials {
		font-size: 12px;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		font-size: 12px;
	}

	.ant-picker .ant-picker-input>input {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.ant-select-dropdown .ant-select-item {
		font-size: 13.5px;
	}

	.input_form_technicials {
		font-size: 13.5px;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		font-size: 13.5px;
	}

	.ant-picker .ant-picker-input>input {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.ant-select-dropdown .ant-select-item {
		font-size: 15.18px;
	}

	.input_form_technicials {
		font-size: 15.18px;
	}

	.ant-select:not(.ant-select-customize-input) .ant-select-selector {
		font-size: 15.18px;
	}

	.ant-picker .ant-picker-input>input {
		font-size: 15.18px;
	}
}