.container__questions {
	width: 100%;
	height: 100%;
	display: flex;
	background-color: white;
}

.container__questions .question_tab {
	width: 100%;
	height: 100%;
	background-color: white;
	display: flex;
	flex-flow: column;
}

.question_row {
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 25.74px;
	padding-right: 89px;
	padding-left: 11px;
}

//  ----------------
.container__questions .question_tab .question_row .single__text__description {
	color: #5A607F;
	font-size: 14px;
	padding: 0 !important;
	margin: 0 !important;
	padding-left: 35.15px !important;
}

.container__questions .question_tab .columna__questions .row__questions .switch__box__content {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
}

.container__questions .question_tab .columna__questions .row__questions .switch__box__content .text_switch {
	margin: 0;
	padding: 0;
}

//  ----------------

.buttons_row {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.bold {
	font-weight: 700;
}

.item__question__margin {
	margin-bottom: 5px !important;
}

.row__questions {
	display: flex;
	align-items: center;
}

.columna__questions {
	display: flex;
	width: 100%;
	padding-left: 41.15px !important;
	padding-right: 89px !important;
}

.space-between {
	display: flex;
	align-items: space-between;
}

.row_center {
	display: flex;
	align-items: center;
}

.flow__column {
	width: 100%;
	display: flex;
	flex-flow: column;
	justify-content: center;
}

.row__questions h4 {
	font-size: 14px
}

.btn__questions {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 40px;
	font-size: 12px;
	border-radius: 3px;
}

.question-class,
.question-kind {
	width: 100%;
	font-size: 14px;
}

.content__box__questions {
	width: 100%;
	min-height: 65px;
	display: flex;
	align-items: center;
}

.question_tab .intervalo__question {
	background: #F7FAFC;
	display: flex;
	align-items: center;
	padding-right: 102.46px;
}

.question-class .lable_font_size {
	font-size: 14px;
}

.font_size_14 {
	font-size: 14px;
}

.text_switch {
	font-size: 12px;
}

.button_section_detail {
	width: 100%;
	background: white;
	gap: 8px;
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	flex: 1;
	padding-right: 89px !important;
	padding-top: 40.74px !important;
	padding-bottom: 24.16px;
}

.button_section_detail .btn_cancelar_question {
	background: transparent;
	width: 83px;
	height: 34px;
	border: 1px solid #BFCCDA;
	border-radius: 3px;
	background: white;
	color: #001737;
	font-size: 12px;
}

.button_section_detail .btn_cancelar_question:hover {
	background: silver
}

.button_section_detail .btn_guardar_question {
	color: #001737;
	width: 83px;
	height: 34px;
	background: #DDE4EB;
	border: 1px solid #0060FF;
	border-radius: 3px;
	font-size: 12px;
}

.button_section_detail .btn_guardar_question:hover {
	background: #2B80FF;
	color: white;
}

.delete__innecesary__padding__left {
	padding: 0 !important;
}

@media screen and (min-width: 1280px) {
	.container__questions .question_tab .question_row .single__text__description {
		font-size: 14px;
	}

	.btn__questions {
		font-size: 12px;
	}

	.question-class .lable_font_size {
		font-size: 14px;
	}

	.font_size_14 {
		font-size: 14px;
	}

	.text_switch {
		font-size: 12px;
	}

	.button_section_detail .btn_cancelar_question {
		width: 83px;
		height: 34px;
		font-size: 12px;
	}

	.button_section_detail .btn_guardar_question {
		width: 83px;
		height: 34px;
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.container__questions .question_tab .question_row .single__text__description {
		font-size: 15.75px;
	}

	.btn__questions {
		font-size: 13.5px;
	}

	.question-class .lable_font_size {
		font-size: 15.75px;
	}

	.font_size_14 {
		font-size: 15.75px;
	}

	.text_switch {
		font-size: 13.5px;
	}

	.button_section_detail .btn_cancelar_question {
		width: 93.37px;
		height: 38.25px;
		font-size: 13.5px;
	}

	.button_section_detail .btn_guardar_question {
		width: 93.37px;
		height: 38.25px;
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.container__questions .question_tab .question_row .single__text__description {
		font-size: 17.71px;
	}

	.btn__questions {
		font-size: 15.18px;
	}

	.question-class .lable_font_size {
		font-size: 17.71px;
	}

	.font_size_14 {
		font-size: 17.71px;
	}

	.text_switch {
		font-size: 15.18px;
	}

	.button_section_detail .btn_cancelar_question {
		width: 105.04px;
		height: 43.03px;
		font-size: 15.18px;
	}

	.button_section_detail .btn_guardar_question {
		width: 105.04px;
		height: 43.03px;
		font-size: 15.18px;
	}
}