.container_general {
	padding: 31.7px 74.25px 0 30.25px;
	display: flex;
	gap: 34px;
}

.text12font {
	font-size: 12px;
	margin: 0;
}

.text14font {
	font-size: 14px;
	margin: 0;
}

.text16font {
	font-size: 16px;
	margin: 0;
}

.bold {
	font-weight: bold;
}

.color5A607F {
	color: #5A607F;
}

.color959595 {
	color: #959595;
}

.flex {
	display: flex;
}

.flex_column {
	display: flex;
	flex-direction: column
}

.divider {
	width: 45%;
	display: flex;
	flex-direction: column;
}

.containerType {
	display: flex;
	margin-bottom: 34px;
	margin-top: 19.48px;
	gap: 23px;
}

.panelType {
	background: #E9F4FF;
	padding: 20px 8px;
	height: 41px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.typeOt {
	font-size: 22px;
	color: #131523;
	font-weight: bold;
	margin: 0;
}

.category {
	display: flex;
	flex-direction: column;
}

.color172B4D {
	color: #172B4D;
}

.container_date {
	display: flex;
	gap: 28px;
}

.margin22 {
	margin-top: 22px;
}

.detailView {
	color: #2B80FF;
	font-weight: bold;
	cursor: pointer;
	margin-bottom: 13.42px;
	width: 19%;
}

.container_detail {
	display: flex;
	background: #FFFFFF;
	border: 1px solid #7070702E;
	padding: 21.93px 32.52px 30px 15px;
	margin-top: 24px;
	margin-bottom: 31.44px;
}

.avatar_user {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 50px;
	height: 50px;
	background-color: #F53C56;
	font-size: 24px;
	font-weight: bold;
}

.description_user {
	display: flex;
	flex-direction: column;
	margin-left: 13.84px;
	width: 63%;
}

.subcontainer_title {
	display: flex;
	gap: 4px;
	align-items: center;
}

.user_name {
	font-size: 14px;
	color: #5A607F;
	margin: 0;
}

.icon_edit {
	color: #2B80FF;
	font-size: 14px;
	cursor: pointer;
}

.user_text {
	font-size: 12px;
	color: #2B80FF;
	margin: 0;
}

.container_assistant {
	display: flex;
	margin-top: 6px;
	margin-bottom: 14.85px;
}

.avatar_assistant {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 20px;
	height: 20px;
	font-size: 12px;
	font-weight: bold;
	margin-right: 11px;
}

.container_route {
	width: 100%;
	position: relative;
	z-index: 1;
}

.modal_reagendar {
	position: absolute;
	width: 116px;
	height: 40px;
	top: 25px;
	right: 23px;
	background: #2B80FF;
	border-radius: 5px;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 5px;
	color: #FFFFFF;
}

.modal_reagendar p {
	margin: 0;
}

.modal_status {
	position: absolute;
	height: 38px;
	top: 52px;
	background: #2B80FF;
	border-radius: 0 5px 5px 0;
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0 10px;
}

.modal_status p {
	color: #FFFFFF;
	font-weight: bold;
	font-size: 15px;
	margin: 0;
}

.modal_direccion {
	position: absolute;
	width: 335px;
	height: 91px;
	bottom: 30px;
	right: 46px;
	background: #F7FAFC;
	border: 1px solid #DDE4EB;
	z-index: 1000;
}

.modal_direccion p {
	margin: 0;
}

.location {
	display: flex;
	align-items: center;
	justify-content: center;
	background: #2B80FF;
	width: 30px;
	height: 30px;
	border-radius: 50%;
}

.icon_location {
	color: #FFFFFF;
	font-size: 15px;
}

.container_direction {
	padding: 16px 55px 18px 21.5px;
	display: flex;
	gap: 21px;
}

.span_direction {
	width: 80%;
}

.puntero {
	display: flex;
	align-items: center;
	justify-content: center;
	/* height: 100vh; */
}



/* Opcional: Estilos adicionales para el contenedor del icono */
.custom_icon {
	display: flex;
	justify-content: center;
	align-items: center;
}

.circle_blue {
	width: 90px;
	height: 90px;
	border-radius: 50%;
	background-color: rgba(210, 217, 247, 0.5);
	border: 1px solid #3E64FF80;
	position: relative;
}

.circle_white {
	width: 24px;
	height: 24px;
	background-color: white;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.circle_red {
	width: 12px;
	height: 12px;
	background-color: red;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}



@media(width >=1280px) {
	.text12font {
		font-size: 12px;
	}

	.text14font {
		font-size: 14px;
	}

	.text16font {
		font-size: 16px;
	}

	.modal_direccion {
		width: 335px;
		height: 91px;
		bottom: 30px;
		right: 46px;
	}

	.user_name {
		font-size: 14px;
	}

	.icon_edit {
		font-size: 14px;
	}

	.user_text {
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.text12font {
		font-size: 13.5px;
	}

	.text14font {
		font-size: 15.75px;
	}

	.text16font {
		font-size: 18px;
	}

	.modal_direccion {
		width: 376.87px;
		height: 102.37px;
		bottom: 30px;
		right: 43px;
	}

	.user_name {
		font-size: 15.74px;
	}

	.icon_edit {
		font-size: 15.75px;
	}

	.user_text {
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.text12font {
		font-size: 15.18px;
	}

	.text14font {
		font-size: 17.71px;
	}

	.text16font {
		font-size: 20.25px;
	}

	.modal_direccion {
		width: 424px;
		height: 115px;
		bottom: 30px;
		right: 125px;
	}

	.user_name {
		font-size: 17.71px;
	}

	.icon_edit {
		font-size: 17.71px;
	}

	.user_text {
		font-size: 15.18px;
	}
}