.container_header {
	background: #f3f3f3;
	display: flex;
	flex-flow: column;
	justify-content: center;
	height: 110px;
}

.container_header .top {
	display: flex;
	justify-content: space-between;
}

.container_header .sub_container_header {
	display: flex;
	align-items: baseline;
}

.container_header .title_header {
	color: #001737;
	font-weight: bold;
	margin: 0;
	padding: 0;
	font-size: 24px;
}

.container_header .sub_text_header {
	color: #001737;
	font-size: 12px;
}

.button_header {
	width: 182px;
	height: 40px;
	border: 1px solid #2B80FF;
	background: #FFFFFF;
	color: #2B80FF;
	border-radius: 5px;
	font-size: 13px;
}

.button_header:hover {
	background: #2B80FF;
	color: #FFFFFF;
}

.container_button {
	position: relative;
}

.container_button .button_action {
	width: 110px;
	height: 40px;
	border: 1px solid #2B80FF;
	background: #FFFFFF;
	color: #2B80FF;
	border-radius: 5px;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 7px;
	font-size: 12px;
}

.container_button .button_action:hover {
	background: #2B80FF;
	color: #FFFFFF;
}


.container_option {
	position: absolute;
	top: 102%;
	left: 20%;
	transform: translateX(-50%);
	width: 170px;
	height: 76px;
	background: #FFFFFF;
	display: flex;
	flex-direction: column;
	border: 1px solid #E0E0E0;
	border-radius: 3px;
}

.container_option .button_option {
	width: 100%;
	height: 50%;
	background: transparent;
	border: none;
	color: #5A607F;
	font-size: 12px;
}


@media(width >=1280px) {
	.container_header .title_header {
		font-size: 24px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_header {
		width: 182px;
		height: 40px;
		font-size: 13px;
	}

	.container_option {
		width: 170px;
		height: 76px;
	}

	.container_option .button_option {
		font-size: 12px;
	}

	.container_button .button_action {
		width: 110px;
		height: 40px;
		font-size: 12px;
	}
}

@media(width >=1366px) {
	.container_header .title_header {
		font-size: 27px;
	}

	.container_header .sub_text_header {
		font-size: 13.5px;
	}

	.button_header {
		width: 204.75px;
		height: 45px;
		font-size: 14.625px;
	}

	.container_option .button_option {
		font-size: 13.5px;
	}

	.container_option {
		left: 23%;
		width: 191.25px;
		height: 85.5px;
	}

	.container_button .button_action {
		width: 123.75px;
		height: 45px;
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.container_header .title_header {
		font-size: 30.37px;
	}

	.container_header .sub_text_header {
		font-size: 12px;
	}

	.button_header {
		width: 230.34px;
		height: 50.625px;
		font-size: 16.45px;
	}

	.container_option .button_option {
		font-size: 15.18px;
	}

	.container_option {
		left: 23%;
		width: 215.15px;
		height: 96.18px;
	}

	.container_button .button_action {
		width: 139.21px;
		height: 50.62px;
		font-size: 15.18px;
	}
}