.row_breadcum {
	display: flex;
	align-items: center;
}

.title__row_breadcum__absenceForm {
	color: #2B80FF;
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 10px
}

.row_breadcum .subtitle_bold {
	color: #131523;
	font-size: 12px;
	font-weight: bold;
	margin: inherit;
	padding: inherit;
	margin-right: 10px;
}

.row_breadcum .single_text {
	color: #131523;
	font-size: 12px;
	margin: inherit;
	padding: inherit;
}

.subtitle_coment {
	color: #131523;
	font-weight: bold;
	margin-bottom: 2px;
	font-size: 12px;
}

.single_coment {
	color: #131523;
	font-size: 12px;
	margin-bottom: 0;
}

.input_font_form_absences {
	font-size: 12px;
}

@media screen and (min-width: 1280px) {

	.input_font_form_absences {
		font-size: 12px;
	}

	.title__row_breadcum__absenceForm {
		font-size: 14px;
	}

	.row_breadcum .subtitle_bold {
		font-size: 12px;
	}

	.row_breadcum .single_text {
		font-size: 12px;
	}

	.subtitle_coment {
		font-size: 12px;
	}

	.single_coment {
		font-size: 12px;
	}

}

@media screen and (min-width: 1366px) {

	.input_font_form_absences {
		font-size: 13.5px;
	}

	.title__row_breadcum__absenceForm {
		font-size: 15.75px;
	}

	.row_breadcum .subtitle_bold {
		font-size: 13.5px;
	}

	.row_breadcum .single_text {
		font-size: 13.5px;
	}

	.subtitle_coment {
		font-size: 13.5px;
	}

	.single_coment {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {

	.input_font_form_absences {
		font-size: 15.18px;
	}

	.title__row_breadcum__absenceForm {
		font-size: 17.71px;
	}

	.row_breadcum .subtitle_bold {
		font-size: 15.18px;
	}

	.row_breadcum .single_text {
		font-size: 15.18px;
	}

	.subtitle_coment {
		font-size: 15.18px;
	}

	.single_coment {
		font-size: 15.18px;
	}
}

// .NewAbsenceForm .row_breadcum{
//     display: flex;
//     align-items: center;
// }