.container_tooltip {
	display: flex;
	flex-direction: column;
}

.tittle_tooltip {
	color: #868E96;
	font-size: 14px;
	margin-bottom: 15px;
}

.description_tooltip {
	color: #172B4D;
	font-size: 12px;
	width: 155px;
}