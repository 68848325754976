.container_header {
	display: flex;
	justify-content: space-between;
	margin-top: 21px;
}

.text_gallery_description {
	color: #5A607F;
	font-size: 14px;
	padding-left: 48px !important;
	margin: 0 !important;
}

.button_create {
	width: 122.5px;
	height: 40px;
	background: #2B80FF;
	color: white;
	border-radius: 5px;
	font-size: 12px;
	margin-right: 79px;
}

.button_create:hover {
	background: white;
	color: #2B80FF;
	border: 1px solid #2B80FF;
}

.container_graph {
	display: flex;
	flex-direction: column;
	padding-left: 48px;
}

.text_p {
	color: #172B4D;
	font-weight: bold;
	font-size: 14px;
	margin: 0;
}

.text_blue {
	color: #2B80FF;
	font-weight: bold;
	font-size: 14px;
	margin: 0;
}

@media(width >=1280px) {
	.text_gallery_description {
		font-size: 14px;
	}

	.button_create {
		width: 122.5px;
		height: 40px;
		font-size: 12px;
	}

	.text_p {
		font-size: 14px;
	}

	.text_blue {
		font-size: 14px;
	}
}

@media(width >=1366px) {
	.text_gallery_description {
		font-size: 15.75px;
	}

	.button_create {
		width: 137.81px;
		height: 45px;
		font-size: 13.5px;
	}

	.text_p {
		font-size: 15.75px;
	}

	.text_blue {
		font-size: 15.75px;
	}
}

@media(width >=1920px) {
	.text_gallery_description {
		font-size: 17.71px;
	}

	.button_create {
		width: 155px;
		height: 50.62px;
		font-size: 15.18px;
	}

	.text_p {
		font-size: 17.71px;
	}

	.text_blue {
		font-size: 17.71px;
	}
}