.root_card {
	min-width: 100vw;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.card_event {
	border: 1px solid #E0E0E0;
	border-radius: 3px;
	padding: 20px;
	display: flex;
	flex-flow: column;
	background: white;
	width: 272px;
}

.space_between {
	display: flex;
	justify-content: space-between;
	margin-top: 6px;
}

.timePicker {
	width: 47%;
}

.flex_end {
	display: flex;
	justify-content: flex-end;
	margin-top: 23px;
}

.btn_normal {
	background: transparent;
	font-size: 12px;
	color: #F53C56;
	z-index: 10;
	margin-right: 14px;
	width: 87px;
	height: 34px;
	cursor: pointer;
	border: 0.5px solid #F53C56;
	border-radius: 5px;
}

.btn_silver {
	background: #DDE4EB;
	font-size: 12px;
	color: #5A607F;
	z-index: 10;
	border-radius: 3px;
	width: 93px;
	height: 34px;
	cursor: pointer;
}

.btn_silver:hover {
	background: #2B80FF;
	color: white;
}