.subtitle {
	font-size: 14px;
	font-weight: bold;
	color: #2B80FF;
	margin-bottom: 12px;
}

.blue {
	color: #2B80FF;
}

.select {
	width: 100%;
	background: white;
	border: 1px solid #d9d9d9;
	border-radius: 2px;
	outline: 0;
	padding: 4px 11px 4px;
	margin-top: 8px;

	:focus {
		border: 1px solid #40a9ff;
	}
}

.createUser_left,
.createUser_right {
	width: 49%;
}

.input {
	width: 100%;
	border: none;
	outline: none;
	display: flex;
	justify-content: space-between;
	align-items: center;
	border-radius: 5px;
	border: 1px solid #E0E0E0;
	padding: 11.5px 12.5px;
	font-weight: 400;
	font-size: 12px;
	color: #868D96;

	::placeholder {
		color: silver;
	}
}

.row {
	display: flex;
	align-items: center;
}

.column {
	display: flex;
	flex-flow: column
}

.align-item-center {
	display: flex;
	align-items: center
}

.row-center {
	display: flex;
	justify-content: center
}

.flex-end {
	display: flex;
	;
	justify-content: flex-end
}

.space-between {
	display: flex;
	justify-content: space-between;
	align-items: center
}

.column_center {
	display: flex;
	justify-content: center
}

.ant-input-group .ant-input {
	width: 100%;
	height: 40px;
	margin-bottom: 0;
	text-align: inherit;
}

.input_form_users {
	font-size: 12px;
}

@media screen and (min-width: 1280px) {
	.input_form_users {
		font-size: 12px;
	}
}

@media screen and (min-width: 1366px) {
	.input_form_users {
		font-size: 13.5px;
	}
}

@media screen and (min-width: 1920px) {
	.input_form_users {
		font-size: 15.18px;
	}
}