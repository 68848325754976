.container_first {
	display: flex;
	gap: 17px;
	margin-bottom: 16px;
}

.subcontainer_first {
	display: flex;
	flex-direction: column;
	width: 50%;
}

.container_second {
	display: flex;
	flex-direction: column;
}