.text {
	font-size: 12px;
	color: #959595;
}

.container_button {
	display: flex;
	justify-content: flex-end;
	align-items: flex-end;
	min-height: 200px;
}

.button_close {
	width: 113px;
	height: 40px;
	background: transparent;
	color: #F53C56;
	border: 1px solid #F53C56;
	border-radius: 5px;
	font-size: 12px;
}

.button_close:hover {
	background: #F53C56;
	color: #fff;
	border: 1px solid #fff;
	border-radius: 5px;
}

@media(width >=1280px) {

	.text {
		font-size: 12px;
	}

	.button_close {
		width: 113px;
		height: 40px;
		font-size: 12px;
	}
}

@media(width >=1366px) {

	.text {
		font-size: 13.5px;
	}

	.button_close {
		width: 127.12px;
		height: 45px;
		font-size: 13.5px;
	}
}

@media(width >=1920px) {
	.text {
		font-size: 15.18px;
	}

	.button_close {
		width: 143px;
		height: 50.62px;
		font-size: 13.5px;
	}
}